import * as React from 'react';
import { lazy } from 'react';
import { Redirect, Route } from 'react-router-dom';

export const ONBOARDING_ROUTES = [
    <Route
        key="[OnboardingRoutes][Infos]"
        path="/infos"
        component={lazy(() => import('../routesComponents/Onboarding/Onboarding'))}
    />,
    <Redirect key="[OnboardingRoutes][RedirectToInfos]" path="*" to="/infos" />,
];
