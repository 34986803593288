import cn from 'classnames';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { PaletteNames } from '../../../types/theme/theme.types';
import { Tooltip } from '../../designSystem/Tooltip/Tooltip';
import { Typography } from '../../designSystem/Typography/Typography';
import classes from './FormField.module.scss';
import { FormFieldProps } from './FormField.types';

export const FormField = ({ required, error, label, children, classes: receivedClasses = {} }: FormFieldProps) => {
    const { t, ready } = useTranslation('form');

    if (!ready) {
        return null;
    }

    return (
        <div className={cn(classes.root, receivedClasses.root)}>
            {Boolean(label) && (
                <Typography
                    classes={{ root: cn(classes.label, receivedClasses.label) }}
                    variant="body1"
                    component="div"
                >
                    {label}
                    {required && (
                        <Tooltip content={t('requiredField')}>
                            <button tabIndex={-1} type="button">
                                <Typography variant="h5" classes={{ root: classes.required }}>
                                    {'*'}
                                </Typography>
                            </button>
                        </Tooltip>
                    )}
                </Typography>
            )}
            {children}
            {Boolean(error) && (
                <Typography
                    classes={{ root: classes.error }}
                    variant="body2"
                    color={PaletteNames.Error}
                    component="div"
                >
                    {typeof error === 'string' ? error : error?.message}
                </Typography>
            )}
        </div>
    );
};
