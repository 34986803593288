export const getTooltipTransitionsSpringProps = (placement: 'top' | 'right' | 'bottom' | 'left') => {
    let fromX = 0;
    let fromY = 0;
    let toX = 0;
    let toY = 0;

    switch (placement) {
        case 'right': {
            fromX = -5;
            toX = 15;
            break;
        }
        case 'bottom': {
            fromY = -5;
            toY = 5;
            break;
        }
        case 'left': {
            fromX = 15;
            toX = -5;
            break;
        }
        default: {
            fromY = 5;
            toY = -5;
        }
    }

    return {
        from: {
            opacity: 0,
            transform: `translate3d(${fromX}px, ${fromY}px, 0)`,
        },
        enter: {
            opacity: 1,
            transform: `translate3d(${toX}px, ${toY}px, 0)`,
        },
        leave: {
            opacity: 0,
            transform: `translate3d(${fromX}px, ${fromY}px, 0)`,
        },
        key: (item: boolean) => `${item ? 'visible' : 'invisible'}_tooltip`,
        unique: true,
    };
};
