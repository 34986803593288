import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { listenToWaitingUser } from '../../../store/waitingUsers/waitingUsersActions/waitingUsersActions';
import {
    hasReceivedWaitingUsersSelector,
    waitingUsersSelector,
} from '../../../store/waitingUsers/waitingUsersReducerSelectors';
import { WaitingUser } from '../../../types/firestore/waitingUsers/waitingUsers.types';

const getMemoizedSelector = (userId: string) =>
    createSelector(
        [hasReceivedWaitingUsersSelector, waitingUsersSelector],
        (hasReceivedWaitingUsers, waitingUsers) => ({
            hasReceivedWaitingUser: hasReceivedWaitingUsers[userId],
            waitingUser: waitingUsers[userId],
        })
    );

interface Parameters {
    enabled: boolean;
}

type UseWaitingUser = (
    userId?: string,
    parameters?: Parameters
) => { waitingUser: WaitingUser | null; loading: boolean; error?: Error };

export const useWaitingUser: UseWaitingUser = (userId, { enabled = true } = {} as Parameters) => {
    const dispatch = useDispatch();
    const { hasReceivedWaitingUser, waitingUser } = useSelector(getMemoizedSelector(userId as string));

    useEffect(() => {
        if (enabled && hasReceivedWaitingUser === undefined && typeof userId === 'string') {
            dispatch(listenToWaitingUser(userId));
        }
    }, [hasReceivedWaitingUser, userId, dispatch, enabled]);

    return {
        waitingUser,
        loading: hasReceivedWaitingUser === null,
        ...(typeof hasReceivedWaitingUser !== 'boolean' &&
            hasReceivedWaitingUser !== null && { error: hasReceivedWaitingUser }),
    };
};
