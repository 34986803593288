import cn from 'classnames';
import * as React from 'react';
import { useMemo } from 'react';

import { ReactComponent as CloseIcon } from '../../../assets/svg/close.svg';
import { hexToRGB } from '../../../utils/styles/hexToRGB';
import { useTheme } from '../../../utils/theme/theme';
import classes from './CloseButton.module.scss';
import { CloseButtonProps } from './CloseButton.types';

export const CloseButton = ({ onClose, classes: receivedClasses = {} }: CloseButtonProps) => {
    const theme = useTheme();
    const textColor = theme.palette.text.primary;
    const textColorRGB = useMemo(() => hexToRGB(textColor), [textColor]);

    return (
        <button
            className={cn(classes.root, receivedClasses?.root)}
            type="button"
            onClick={onClose}
            style={{
                backgroundColor: `rgba(${textColorRGB.join(',')}, .15)`,
            }}
        >
            <CloseIcon
                className={cn(classes.icon, receivedClasses?.icon)}
                style={{
                    fill: textColor,
                }}
            />
        </button>
    );
};
