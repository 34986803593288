import { TFunction } from 'i18next';
import * as yup from 'yup';

import { RolesEnum } from '../../../../types/firestore/users/users.types';

export const getRegisterSchema = (t: TFunction) =>
    yup.object({}).shape({
        role: yup
            .string()
            .oneOf([RolesEnum.RegularUser, RolesEnum.AssociationUser], 'form:invalidRole')
            .required(t('form:requiredField')),
        email: yup.string().email(t('form:invalidEmail')).required(t('form:requiredField')),
        password: yup.string().required(t('form:requiredField')),
    });
