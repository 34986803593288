export type ListenersBucket = {
    [listenerPath: string]: () => void;
};

// eslint-disable-next-line import/no-mutable-exports
export let LISTENERS_BUCKET: ListenersBucket = {};

export const containsListener = (key: any) => Boolean(LISTENERS_BUCKET[JSON.stringify(key)]);

export const addListenerToBucket = (key: any, listener: () => void) => {
    LISTENERS_BUCKET[JSON.stringify(key)] = listener;
};

export const resetListenersBucket = () => {
    LISTENERS_BUCKET = {};
};
