import cn from 'classnames';
import * as React from 'react';
import { forwardRef } from 'react';

import { useTheme } from '../../../utils/theme/theme';
import classes from './Paper.module.scss';
import { PaperProps } from './Paper.types';

export const Paper = forwardRef(
    (
        {
            component: Component = 'div',
            classes: receivedClasses = {},
            children,
            style: receivedStyle,
            ...other
        }: PaperProps,
        ref
    ) => {
        const theme = useTheme();

        const backgroundColor = theme.palette.background.surface;

        return (
            <Component
                {...{ ref }}
                className={cn(classes.root, receivedClasses.root)}
                style={{
                    backgroundColor,
                    boxShadow: theme.components.paper.boxShadow,
                    ...receivedStyle,
                }}
                {...other}
            >
                {children}
            </Component>
        );
    }
);
