import { Dispatch } from 'react';

import { DialogProps } from '../../designSystem/Dialog/Dialog.types';

export enum AuthenticationTypes {
    SignIn = 'signIn',
    Register = 'register',
    ForgotPassword = 'forgotPassword',
}

export type AuthenticateDialogProps = Pick<DialogProps, 'open' | 'onClose'>;

export interface FormProps {
    currentType: AuthenticationTypes;
}

export interface AuthenticateDialogContextProps {
    currentType: AuthenticationTypes;
    setCurrentType: Dispatch<AuthenticationTypes>;
    onClose: () => void;
}
