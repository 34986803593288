import { Action } from 'redux';

import { Booking, Bookings } from '../../../types/firestore/bookings/bookings.types';
import { SignOutAction } from '../../miscellaneous/miscellaneousActions/miscellaneousActions.types';

export const LISTEN_TO_BOOKING_STARTED = 'LISTEN_TO_BOOKING_STARTED';
export const LISTEN_TO_BOOKING_RECEIVED_DATA = 'LISTEN_TO_BOOKING_RECEIVED_DATA';
export const LISTEN_TO_BOOKING_RECEIVED_ERROR = 'LISTEN_TO_BOOKING_RECEIVED_ERROR';

export const LISTEN_TO_ALL_BOOKINGS_BY_USER_ID_STARTED = 'LISTEN_TO_ALL_BOOKINGS_BY_USER_ID_STARTED';
export const LISTEN_TO_ALL_BOOKINGS_BY_USER_ID_RECEIVED_DATA = 'LISTEN_TO_ALL_BOOKINGS_BY_USER_ID_RECEIVED_DATA';
export const LISTEN_TO_ALL_BOOKINGS_BY_USER_ID_RECEIVED_ERROR = 'LISTEN_TO_ALL_BOOKINGS_BY_USER_ID_RECEIVED_ERROR';

export const LISTEN_TO_ALL_BOOKINGS_BY_ASSOCIATION_ID_STARTED = 'LISTEN_TO_ALL_BOOKINGS_BY_ASSOCIATION_ID_STARTED';
export const LISTEN_TO_ALL_BOOKINGS_BY_ASSOCIATION_ID_RECEIVED_DATA =
    'LISTEN_TO_ALL_BOOKINGS_BY_ASSOCIATION_ID_RECEIVED_DATA';
export const LISTEN_TO_ALL_BOOKINGS_BY_ASSOCIATION_ID_RECEIVED_ERROR =
    'LISTEN_TO_ALL_BOOKINGS_BY_ASSOCIATION_ID_RECEIVED_ERROR';

export interface ListenToBookingStartedAction extends Action {
    type: typeof LISTEN_TO_BOOKING_STARTED;
    bookingId: string;
}

export interface ListenToBookingReceivedDataAction extends Action {
    type: typeof LISTEN_TO_BOOKING_RECEIVED_DATA;
    bookingId: string;
    data: Booking;
}

export interface ListenToBookingReceivedErrorAction extends Action {
    type: typeof LISTEN_TO_BOOKING_RECEIVED_ERROR;
    bookingId: string;
    error: Error;
}

export interface ListenToAllBookingsByUserIdStartedAction extends Action {
    type: typeof LISTEN_TO_ALL_BOOKINGS_BY_USER_ID_STARTED;
    userId: string;
}

export interface ListenToAllBookingsByUserIdReceivedDataAction extends Action {
    type: typeof LISTEN_TO_ALL_BOOKINGS_BY_USER_ID_RECEIVED_DATA;
    userId: string;
    data: Bookings;
}

export interface ListenToAllBookingsByUserIdReceivedErrorAction extends Action {
    type: typeof LISTEN_TO_ALL_BOOKINGS_BY_USER_ID_RECEIVED_ERROR;
    userId: string;
    error: Error;
}

export interface ListenToAllBookingsByAssociationIdStartedAction extends Action {
    type: typeof LISTEN_TO_ALL_BOOKINGS_BY_ASSOCIATION_ID_STARTED;
    associationId: string;
}

export interface ListenToAllBookingsByAssociationIdReceivedDataAction extends Action {
    type: typeof LISTEN_TO_ALL_BOOKINGS_BY_ASSOCIATION_ID_RECEIVED_DATA;
    associationId: string;
    data: Bookings;
}

export interface ListenToAllBookingsByAssociationIdReceivedErrorAction extends Action {
    type: typeof LISTEN_TO_ALL_BOOKINGS_BY_ASSOCIATION_ID_RECEIVED_ERROR;
    associationId: string;
    error: Error;
}

export type BookingsReducerActionTypes =
    | ListenToBookingStartedAction
    | ListenToBookingReceivedDataAction
    | ListenToBookingReceivedErrorAction
    | ListenToAllBookingsByUserIdStartedAction
    | ListenToAllBookingsByUserIdReceivedDataAction
    | ListenToAllBookingsByUserIdReceivedErrorAction
    | ListenToAllBookingsByAssociationIdStartedAction
    | ListenToAllBookingsByAssociationIdReceivedDataAction
    | ListenToAllBookingsByAssociationIdReceivedErrorAction
    | SignOutAction;
