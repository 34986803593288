import cn from 'classnames';
import * as React from 'react';
import { useMemo, useState } from 'react';

import { useCallbackOpen } from '../../../../../../hooks/useCallbackOpen';
import { hexToRGB } from '../../../../../../utils/styles/hexToRGB';
import { useTheme } from '../../../../../../utils/theme/theme';
import { PopperPaper } from '../../../../../designSystem/PopperPaper/PopperPaper';
import { HeaderNestedActionsProps } from '../HeaderNestedActions.types';
import classes from './WithPopperPaperNestedActions.module.scss';

export const WithPopperPaperNestedActions = ({
    icon: Icon,
    classes: receivedClasses = {},
    children,
}: Pick<HeaderNestedActionsProps, 'icon' | 'children' | 'classes'>) => {
    const { palette } = useTheme();
    const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(null);
    const [isHovering, setIsHovering, setIsNotHovering] = useCallbackOpen();
    const textColor = palette.text.primary;
    const rgbTextColor = useMemo(() => hexToRGB(textColor), [textColor]);

    return (
        <>
            <div
                className={cn(classes.root, receivedClasses.root)}
                ref={setAnchorElement}
                onMouseEnter={setIsHovering}
                onFocus={setIsHovering}
                onMouseLeave={setIsNotHovering}
                onBlur={setIsNotHovering}
                role="button"
                tabIndex={0}
            >
                <PopperPaper open={isHovering} {...{ anchorElement }}>
                    {children}
                </PopperPaper>
                <Icon className={classes.icon} style={{ fill: `rgba(${rgbTextColor.join(', ')}, .85)` }} />
            </div>
        </>
    );
};
