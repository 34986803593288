import * as React from 'react';
import { lazy } from 'react';
import { Redirect, Route } from 'react-router-dom';

export const VERIFY_EMAIL_ROUTES = [
    <Route
        key="[VerifyEmailRoutes][VerifyEmail]"
        path="/verify-email"
        component={lazy(() => import('../routesComponents/VerifyEmail/VerifyEmail'))}
    />,
    <Redirect key="[VerifyEmailRoutes][RedirectToWaitingForVerification]" from="*" to="/verify-email" />,
];
