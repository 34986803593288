import cn from 'classnames';
import { FirebaseError } from 'firebase/app';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PaletteNames } from '../../../../types/theme/theme.types';
import { Button } from '../../../designSystem/Button/Button';
import { ButtonVariants } from '../../../designSystem/Button/Button.types';
import { Typography } from '../../../designSystem/Typography/Typography';
import { handleSignInWithPopup } from './SocialProvidersButtons.actions';
import { SOCIAL_PROVIDERS_BUTTONS_DATA } from './SocialProvidersButtons.data';
import classes from './SocialProvidersButtons.module.scss';
import { SocialProviders, SocialProvidersButtonsProps } from './SocialProvidersButtons.types';

export const SocialProvidersButtons = ({ onClose, classes: receivedClasses = {} }: SocialProvidersButtonsProps) => {
    const { t, ready } = useTranslation('firebaseErrorMessages');
    const socialProvidersButtonsEntries = useMemo(() => Object.entries(SOCIAL_PROVIDERS_BUTTONS_DATA), []);

    const [error, setError] = useState<FirebaseError>();

    const handleClick = useCallback(
        (providerId: SocialProviders) => async () => {
            try {
                await handleSignInWithPopup(providerId);
                onClose();
            } catch (e) {
                setError(e as any);
            }
        },
        [onClose]
    );

    if (!ready) {
        return null;
    }

    return (
        <div className={cn(classes.root, receivedClasses.root)}>
            {Boolean(error) && (
                <Typography classes={{ root: classes.error }} color={PaletteNames.Error}>
                    {t(`firebaseErrorMessages:${error?.code}`)}
                </Typography>
            )}
            {socialProvidersButtonsEntries.map(([providerId, { icon: Icon, name, color }]) => (
                <Button
                    key={`social_provider_${providerId}`}
                    classes={{ root: classes.button }}
                    variant={ButtonVariants.Outlined}
                    onClick={handleClick(providerId as SocialProviders)}
                    {...{ color }}
                >
                    <Icon className={classes.icon} />
                    <span className={classes.typography}>{name}</span>
                </Button>
            ))}
        </div>
    );
};
