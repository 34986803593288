import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { listenToUser } from '../../../store/users/usersActions/usersActions';
import { hasReceivedUsersSelector, usersSelector } from '../../../store/users/usersReducerSelectors';
import { User } from '../../../types/firestore/users/users.types';

const getMemoizedSelector = (userId: string) =>
    createSelector([hasReceivedUsersSelector, usersSelector], (hasReceivedUsers, users) => ({
        hasReceivedUser: hasReceivedUsers[userId],
        user: users[userId],
    }));

type UseUser = (userId?: string) => { user: User | null; loading: boolean; error?: Error };

export const useUser: UseUser = (userId) => {
    const dispatch = useDispatch();
    const { hasReceivedUser, user } = useSelector(getMemoizedSelector(userId as string));

    useEffect(() => {
        if (hasReceivedUser === undefined && typeof userId === 'string') {
            dispatch(listenToUser(userId));
        }
    }, [hasReceivedUser, userId, dispatch]);

    return {
        user,
        loading: hasReceivedUser === null,
        ...(typeof hasReceivedUser !== 'boolean' && hasReceivedUser !== null && { error: hasReceivedUser }),
    };
};
