export enum Links {
    Donate = 'donate',
    Faq = 'faq',
    Contact = 'contact',
}

export interface LinkData {
    linkTo: string;
    openInNewTab?: boolean;
    translationKey: string;
}

export type LinksData = {
    [linkId in Links]: LinkData;
};
