import { Reducer } from 'redux';

import { SIGN_OUT } from '../miscellaneous/miscellaneousActions/miscellaneousActions.types';
import {
    LISTEN_TO_USER_RECEIVED_DATA,
    LISTEN_TO_USER_RECEIVED_ERROR,
    LISTEN_TO_USER_STARTED,
    UsersReducerActionTypes,
} from './usersActions/usersActions.types';
import { UsersReducerState } from './usersReducer.types';
import { USERS_REDUCER_INITIAL_STATE } from './usersReducerInitialState';

export const usersReducer: Reducer<UsersReducerState, UsersReducerActionTypes> = (
    state = USERS_REDUCER_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case LISTEN_TO_USER_STARTED:
            return {
                ...state,
                hasReceivedUsers: {
                    ...state.hasReceivedUsers,
                    [action.userId]: null,
                },
            };

        case LISTEN_TO_USER_RECEIVED_DATA:
            return {
                ...state,
                hasReceivedUsers: {
                    ...state.hasReceivedUsers,
                    [action.userId]: true,
                },
                users: {
                    ...state.users,
                    [action.userId]: action.data,
                },
            };

        case LISTEN_TO_USER_RECEIVED_ERROR:
            return {
                ...state,
                hasReceivedUsers: {
                    ...state.hasReceivedUsers,
                    [action.userId]: action.error,
                },
            };

        case SIGN_OUT:
            return USERS_REDUCER_INITIAL_STATE;

        default:
            return state;
    }
};
