import { Reducer } from 'redux';

import { SIGN_OUT } from '../miscellaneous/miscellaneousActions/miscellaneousActions.types';
import {
    BookingsReducerActionTypes,
    LISTEN_TO_ALL_BOOKINGS_BY_ASSOCIATION_ID_RECEIVED_DATA,
    LISTEN_TO_ALL_BOOKINGS_BY_ASSOCIATION_ID_RECEIVED_ERROR,
    LISTEN_TO_ALL_BOOKINGS_BY_ASSOCIATION_ID_STARTED,
    LISTEN_TO_ALL_BOOKINGS_BY_USER_ID_RECEIVED_DATA,
    LISTEN_TO_ALL_BOOKINGS_BY_USER_ID_RECEIVED_ERROR,
    LISTEN_TO_ALL_BOOKINGS_BY_USER_ID_STARTED,
    LISTEN_TO_BOOKING_RECEIVED_DATA,
    LISTEN_TO_BOOKING_RECEIVED_ERROR,
    LISTEN_TO_BOOKING_STARTED,
} from './bookingsActions/bookingsActions.types';
import { BookingsReducerState } from './bookingsReducer.types';
import { BOOKINGS_REDUCER_INITIAL_STATE } from './bookingsReducerInitialState';

export const bookingsReducer: Reducer<BookingsReducerState, BookingsReducerActionTypes> = (
    state = BOOKINGS_REDUCER_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case LISTEN_TO_BOOKING_STARTED:
            return {
                ...state,
                hasReceivedBookings: {
                    ...state.hasReceivedBookings,
                    [action.bookingId]: null,
                },
            };

        case LISTEN_TO_BOOKING_RECEIVED_DATA:
            return {
                ...state,
                hasReceivedBookings: {
                    ...state.hasReceivedBookings,
                    [action.bookingId]: true,
                },
                bookings: {
                    ...state.bookings,
                    [action.bookingId]: action.data,
                },
            };

        case LISTEN_TO_BOOKING_RECEIVED_ERROR:
            return {
                ...state,
                hasReceivedBookings: {
                    ...state.hasReceivedBookings,
                    [action.bookingId]: action.error,
                },
            };

        case LISTEN_TO_ALL_BOOKINGS_BY_USER_ID_STARTED:
            return {
                ...state,
                hasReceivedAllBookingsByUserId: {
                    ...state.hasReceivedAllBookingsByUserId,
                    [action.userId]: null,
                },
            };

        case LISTEN_TO_ALL_BOOKINGS_BY_USER_ID_RECEIVED_DATA:
            return {
                ...state,
                hasReceivedAllBookingsByUserId: {
                    ...state.hasReceivedAllBookingsByUserId,
                    [action.userId]: true,
                },
                hasReceivedBookings: {
                    ...state.hasReceivedBookings,
                    ...Object.keys(action.data).reduce(
                        (acc, bookingId) => ({
                            ...acc,
                            [bookingId]: true,
                        }),
                        {} as BookingsReducerState['hasReceivedBookings']
                    ),
                },
                bookings: {
                    ...state.bookings,
                    ...action.data,
                },
            };

        case LISTEN_TO_ALL_BOOKINGS_BY_USER_ID_RECEIVED_ERROR:
            return {
                ...state,
                hasReceivedAllBookingsByUserId: {
                    ...state.hasReceivedAllBookingsByUserId,
                    [action.userId]: action.error,
                },
            };

        case LISTEN_TO_ALL_BOOKINGS_BY_ASSOCIATION_ID_STARTED:
            return {
                ...state,
                hasReceivedAllBookingsByAssociationId: {
                    ...state.hasReceivedAllBookingsByAssociationId,
                    [action.associationId]: null,
                },
            };

        case LISTEN_TO_ALL_BOOKINGS_BY_ASSOCIATION_ID_RECEIVED_DATA:
            return {
                ...state,
                hasReceivedAllBookingsByAssociationId: {
                    ...state.hasReceivedAllBookingsByAssociationId,
                    [action.associationId]: true,
                },
                hasReceivedBookings: {
                    ...state.hasReceivedBookings,
                    ...Object.keys(action.data).reduce(
                        (acc, bookingId) => ({
                            ...acc,
                            [bookingId]: true,
                        }),
                        {} as BookingsReducerState['hasReceivedBookings']
                    ),
                },
                bookings: {
                    ...state.bookings,
                    ...action.data,
                },
            };

        case LISTEN_TO_ALL_BOOKINGS_BY_ASSOCIATION_ID_RECEIVED_ERROR:
            return {
                ...state,
                hasReceivedAllBookingsByAssociationId: {
                    ...state.hasReceivedAllBookingsByAssociationId,
                    [action.associationId]: action.error,
                },
            };

        case SIGN_OUT:
            return BOOKINGS_REDUCER_INITIAL_STATE;

        default:
            return state;
    }
};
