import { animated, useTransition } from '@react-spring/web';
import cn from 'classnames';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { Portal } from 'react-portal';
import { useMediaQuery } from 'react-responsive';

import { Paper } from '../Paper/Paper';
import classes from './Dialog.module.scss';
import { DIALOG_TRANSITIONS_SPRING_PROPS } from './Dialog.springProps';
import { DialogProps } from './Dialog.types';

const rootElement = document.getElementById('root');

export const Dialog = ({ open, onClose, fullScreen, children, classes: receivedClasses = {} }: DialogProps) => {
    const forceFullScreen = useMediaQuery({
        query: '(max-width: 400px)',
    });
    const transitions = useTransition(open, DIALOG_TRANSITIONS_SPRING_PROPS);

    const handleClose = useCallback(() => {
        if (typeof onClose !== 'function') {
            return;
        }
        onClose();
    }, [onClose]);

    useEffect(() => {
        if (open) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'auto';
        }
        return () => {
            document.body.style.overflowY = 'auto';
        };
    }, [open]);

    return (
        <Portal node={rootElement}>
            {transitions(
                (props, item) =>
                    item && (
                        <animated.div
                            className={cn(
                                classes.root,
                                (fullScreen || forceFullScreen) && classes.fullScreen,
                                receivedClasses.root
                            )}
                            style={props}
                        >
                            <div className={classes.wrapper}>
                                <div className={classes.backdrop} onClick={handleClose} aria-hidden="true" />
                                <Paper classes={{ root: cn(classes.paper, receivedClasses.paper) }}>{children}</Paper>
                            </div>
                        </animated.div>
                    )
            )}
        </Portal>
    );
};
