import { animated, config, to, useSpring } from '@react-spring/web';
import cn from 'classnames';
import { forwardRef, useCallback, useMemo } from 'react';
import * as React from 'react';

import { CommonColors, PaletteNames } from '../../../types/theme/theme.types';
import { useTheme } from '../../../utils/theme/theme';
import { Typography } from '../Typography/Typography';
import classes from './Button.module.scss';
import { ButtonProps, ButtonVariants } from './Button.types';

const DEFAULT_BRIGHT_LAYER_SPRING_PROPS = {
    opacity: 0,
    config: config.stiff,
};

const ButtonComponent = forwardRef(
    (
        {
            component: Component = animated.button,
            iconButton,
            color,
            variant = ButtonVariants.Text,
            disabled,
            children,
            onClick,
            onMouseEnter,
            onMouseLeave,
            onFocus,
            onBlur,
            classes: receivedClasses = {},
            style: receivedStyle,
            ...other
        }: ButtonProps,
        ref
    ) => {
        const theme = useTheme();

        const colorValue = useMemo(() => {
            if (disabled) {
                return theme.palette.action.disabled;
            }
            if (Object.values(CommonColors).includes(color as CommonColors)) {
                if (variant === ButtonVariants.Contained) {
                    return color === CommonColors.Black ? theme.palette.commons.white : theme.palette.commons.black;
                }
                return theme.palette.commons[color as CommonColors];
            }
            if (color) {
                if (variant === ButtonVariants.Contained) {
                    return theme.palette[color as PaletteNames].contrastText;
                }
                if (Object.values(PaletteNames).includes(color as PaletteNames)) {
                    return theme.palette[color as PaletteNames].main;
                }
                return color;
            }
            return theme.components.button[variant].color;
        }, [disabled, color, theme, variant]);

        const [brightLayerSpringProps, brightLayerSpringApi] = useSpring(() => DEFAULT_BRIGHT_LAYER_SPRING_PROPS);

        const { color: colorSpring } = useSpring({
            color: colorValue,
            config: config.stiff,
        });

        const showBrightLayer = useCallback(
            () =>
                brightLayerSpringApi.start({
                    opacity: variant !== 'contained' ? 0.05 : 0.2,
                }),
            [brightLayerSpringApi, variant]
        );

        const dismissBrightLayer = useCallback(
            () => brightLayerSpringApi.start(DEFAULT_BRIGHT_LAYER_SPRING_PROPS),
            [brightLayerSpringApi]
        );

        const handleMouseEnter = useCallback(
            (event) => {
                if (typeof onMouseEnter === 'function') {
                    onMouseEnter(event);
                }
                showBrightLayer();
            },
            [showBrightLayer, onMouseEnter]
        );

        const handleMouseLeave = useCallback(
            (event) => {
                if (typeof onMouseLeave === 'function') {
                    onMouseLeave(event);
                }
                dismissBrightLayer();
            },
            [dismissBrightLayer, onMouseLeave]
        );

        const handleFocus = useCallback(
            (event) => {
                if (typeof onFocus === 'function') {
                    onFocus(event);
                }
                showBrightLayer();
            },
            [showBrightLayer, onFocus]
        );
        const handleBlur = useCallback(
            (event) => {
                if (typeof onBlur === 'function') {
                    onBlur(event);
                }
                dismissBrightLayer();
            },
            [dismissBrightLayer, onBlur]
        );

        const handleClick = useCallback(
            (event) => {
                if (disabled || typeof onClick !== 'function') {
                    return;
                }
                onClick(event);
            },
            [disabled, onClick]
        );

        return (
            <Component
                {...{ ref }}
                className={cn(
                    classes.root,
                    classes[`variant-${variant}`],
                    disabled && classes.disabled,
                    iconButton && classes.iconButton,
                    receivedClasses.root
                )}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onClick={handleClick}
                {...(variant === ButtonVariants.Outlined && {
                    style: {
                        border: to(colorSpring, (value: string) => `1px solid ${value}`),
                        ...receivedStyle,
                    },
                })}
                style={{
                    ...receivedStyle,
                    color: colorSpring,
                }}
                {...(other as any)}
            >
                <animated.div
                    className={cn(classes.brightLayer, receivedClasses.brightLayer)}
                    style={brightLayerSpringProps}
                />
                <Typography
                    italic
                    component={animated.span}
                    classes={{ root: cn(classes.typography, receivedClasses.typography) }}
                    variant="button"
                >
                    {children}
                </Typography>
            </Component>
        );
    }
);

const ContainedButton = forwardRef(({ children, ...other }: ButtonProps, ref) => {
    const theme = useTheme();
    const { color, disabled, style } = other;

    const backgroundColorValue = useMemo(() => {
        if (disabled) {
            return theme.palette.action.disabledBackground;
        }
        if (!color) {
            return theme.components.button.contained.backgroundColor;
        }
        if (Object.values(CommonColors).includes(color as CommonColors)) {
            return theme.palette.commons[color as CommonColors];
        }
        return theme.palette[color as PaletteNames].main;
    }, [disabled, color, theme]);

    const springProps = useSpring({
        backgroundColor: backgroundColorValue,
        config: config.stiff,
    });

    return (
        <ButtonComponent
            {...other}
            ref={ref as any}
            style={
                {
                    ...springProps,
                    ...style,
                } as any
            }
        >
            {children}
        </ButtonComponent>
    );
});

export const Button = forwardRef(({ variant = ButtonVariants.Text, children, ...props }: ButtonProps, ref) => {
    if (variant === ButtonVariants.Contained) {
        return (
            <ContainedButton ref={ref as any} {...{ variant }} {...props}>
                {children}
            </ContainedButton>
        );
    }
    return (
        <ButtonComponent ref={ref as any} {...{ variant }} {...props}>
            {children}
        </ButtonComponent>
    );
});
