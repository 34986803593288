import { DrawerPlacements } from './Drawer.types';

export const getDrawerTransitionsSpringProps = (placement?: DrawerPlacements) => ({
    from: {
        transform: `translate3d(${placement === DrawerPlacements.Right ? 100 : -100}%, 0, 0)`,
    },
    enter: {
        transform: `translate3d(0%, 0, 0)`,
    },
    leave: {
        transform: `translate3d(${placement === DrawerPlacements.Right ? 100 : -100}%, 0, 0)`,
    },
    unique: true,
    key: (item: boolean) => `${item ? 'visible' : 'invisible'}_drawer`,
});
