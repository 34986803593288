export const POPPER_PAPER_TRANSITIONS_SPRING_PROPS = {
    from: {
        opacity: 0,
        transform: 'translate3d(0, 15px, 0)',
    },
    enter: {
        opacity: 1,
        transform: 'translate3d(0, 0px, 0)',
    },
    leave: {
        opacity: 0,
        transform: 'translate3d(0, 15px, 0)',
    },
    key: (item: boolean) => `${item ? 'visible' : 'invisible'}_popper_paper`,
    unique: true,
};
