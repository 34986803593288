import { Reducer } from 'redux';

import {
    AUTH_STATE_CHANGED_NO_USER,
    AUTH_STATE_CHANGED_RECEIVED_USER,
    MiscellaneousReducerActionTypes,
    SET_IS_PERSISTENT_DRAWER_OPENED,
    SET_IS_HEADER_TRANSPARENT,
} from './miscellaneousActions/miscellaneousActions.types';
import { MiscellaneousReducerState } from './miscellaneousReducer.types';
import { MISCELLANEOUS_REDUCER_INITIAL_STATE } from './miscellaneousReducerInitialState';

export const miscellaneousReducer: Reducer<MiscellaneousReducerState, MiscellaneousReducerActionTypes> = (
    state = MISCELLANEOUS_REDUCER_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case AUTH_STATE_CHANGED_RECEIVED_USER: {
            return {
                ...state,
                isWaitingForAuthStateChange: false,
                firebaseUser: action.firebaseUser,
            };
        }

        case AUTH_STATE_CHANGED_NO_USER:
            return {
                ...state,
                isWaitingForAuthStateChange: false,
                firebaseUser: null,
            };

        case SET_IS_HEADER_TRANSPARENT:
            return {
                ...state,
                isHeaderTransparent: action.isHeaderTransparent,
            };

        case SET_IS_PERSISTENT_DRAWER_OPENED:
            return {
                ...state,
                isPersistentDrawerOpened: action.isPersistentDrawerOpened,
            };

        default:
            return state;
    }
};
