import * as React from 'react';
import { lazy } from 'react';
import { Redirect, Route } from 'react-router-dom';

export const EMAIL_VERIFICATION_ROUTES = [
    <Route
        key="[EmailVerificationRoutes][EmailVerfication]"
        path="/email-verification"
        component={lazy(() => import('../routesComponents/EmailVerification/EmailVerification'))}
    />,
    <Redirect key="[EmailVerificationRoutes][RedirectToEmailVerification]" from="*" to="/email-verification" />,
];
