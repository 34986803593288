import { useSpring, animated } from '@react-spring/web';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useCallbackOpen } from '../../../hooks/useCallbackOpen';
import { CommonColors } from '../../../types/theme/theme.types';
import { Typography } from '../../designSystem/Typography/Typography';
import classes from './Footer.module.scss';
import { FooterLinkProps } from './FooterLink.types';

const FooterLink = ({
    translationKey,
    openInNewTab,
    linkTo,
}: Pick<FooterLinkProps, 'translationKey' | 'openInNewTab' | 'linkTo'>) => {
    const { t } = useTranslation('footer');

    const [isHovering, setIsHovering, setIsNotHovering] = useCallbackOpen();
    const springProps = useSpring({
        textColor: isHovering ? CommonColors.White : 'rgba(255, 255, 255, 0.8)',
    });
    const content = (
        <Typography
            classes={{ root: classes.linkTypography }}
            onMouseEnter={setIsHovering}
            onFocus={setIsHovering}
            onMouseLeave={setIsNotHovering}
            onBlur={setIsNotHovering}
            component={animated.span}
            style={{ color: springProps.textColor } as any}
        >
            {t(translationKey)}
        </Typography>
    );
    return (
        <li className={classes.li}>
            {Boolean(openInNewTab) && (
                <a href={linkTo} target="_blank" rel="noopener noreferrer">
                    {content}
                </a>
            )}
            {!openInNewTab && <Link to={linkTo}>{content}</Link>}
        </li>
    );
};

export default FooterLink;
