import { ElementType, HTMLProps, ReactNode } from 'react';

import { CommonColors, PaletteNames } from '../../../types/theme/theme.types';
import { ClassNames } from '../../../utils/styles/styles.types';

export enum ButtonVariants {
    Text = 'text',
    Outlined = 'outlined',
    Contained = 'contained',
}

export type ButtonProps = Omit<HTMLProps<'button'>, 'size' | 'color' | 'ref'> & {
    component?: ElementType;
    iconButton?: boolean;
    disabled?: boolean;
    variant?: ButtonVariants;
    color?: PaletteNames | CommonColors | string;
    children?: ReactNode;
    classes?: ClassNames<'root' | 'brightLayer' | 'typography'>;
};
