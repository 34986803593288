import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { HashLink } from 'react-router-hash-link';

import { ReactComponent as DonateIcon } from '../../../../assets/svg/email.svg';
import { ReactComponent as HowAreWeIcon } from '../../../../assets/svg/face.svg';
import { ReactComponent as MenuIcon } from '../../../../assets/svg/menu.svg';
import { ReactComponent as PartnersIcon } from '../../../../assets/svg/person.svg';
import { ReactComponent as ProcessIcon } from '../../../../assets/svg/play-arrow.svg';
import { useCallbackOpen } from '../../../../hooks/useCallbackOpen';
import {
    firebaseUserSelector,
    isWaitingAuthStateChangeSelector,
} from '../../../../store/miscellaneous/miscellaneousReducerSelectors';
import { useTheme } from '../../../../utils/theme/theme';
import { Drawer } from '../../../designSystem/Drawer/Drawer';
import { DrawerPlacements, DrawerVariants } from '../../../designSystem/Drawer/Drawer.types';
import { DrawerContent } from '../../../designSystem/DrawerContent/DrawerContent';
import { HeaderContext } from '../Header.context';
import classes from './HeaderActions.module.scss';
import { HeaderNestedButton } from './HeaderNestedActions/HeaderNestedButton/HeaderNestedButton';
import { NotSignedInActions } from './NotSignedInActions/NotSignedInActions';
import { SignedInActions } from './SignedInActions/SignedInActions';

const Actions = () => {
    const isWaitingForAuthStateChange = useSelector(isWaitingAuthStateChangeSelector);
    const firebaseUser = useSelector(firebaseUserSelector);
    const { t, ready } = useTranslation('landing');
    if (!ready) {
        return null;
    }
    if (isWaitingForAuthStateChange) {
        return null;
    }

    return (
        <>
            {!firebaseUser?.uid ? <NotSignedInActions /> : <SignedInActions />}
            <HashLink smooth to="/#whoAreWe" className={classes.mobileLinks}>
                <HeaderNestedButton icon={HowAreWeIcon}>{t('sections.whoAreWe.title')}</HeaderNestedButton>
            </HashLink>
            <HashLink smooth to="/#process" className={classes.mobileLinks}>
                <HeaderNestedButton icon={ProcessIcon}>{t('sections.process.title')}</HeaderNestedButton>
            </HashLink>
            <a
                href="https://www.helloasso.com/associations/j-social/formulaires/1"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.mobileLinks}
            >
                <HeaderNestedButton icon={DonateIcon}>{t('sections.supportUs.title')}</HeaderNestedButton>
            </a>
            <HashLink smooth to="/#partners" className={classes.mobileLinks}>
                <HeaderNestedButton icon={PartnersIcon}>{t('sections.partners.title')}</HeaderNestedButton>
            </HashLink>
        </>
    );
};

const InDrawerActions = () => {
    const { palette } = useTheme();
    const [open, setOpened, setClosed] = useCallbackOpen();

    return (
        <>
            <button type="button" onClick={setOpened}>
                <MenuIcon className={classes.menuIcon} style={{ fill: palette.text.primary }} />
            </button>
            <Drawer
                variant={DrawerVariants.Temporary}
                onClose={setClosed}
                placement={DrawerPlacements.Right}
                {...{ open }}
            >
                <DrawerContent center>
                    <Actions />
                </DrawerContent>
            </Drawer>
        </>
    );
};

export const HeaderActions = () => {
    const { displayActionsInDrawer } = useContext(HeaderContext);

    if (displayActionsInDrawer) {
        return <InDrawerActions />;
    }

    return <Actions />;
};
