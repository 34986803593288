import cn from 'classnames';
import { useCallback } from 'react';
import * as React from 'react';

import { useTheme } from '../../../utils/theme/theme';
import { Typography } from '../Typography/Typography';
import classes from './Toggle.module.scss';
import { ToggleProps } from './Toggle.types';

export const Toggle = ({ items, value, onChange, classes: receivedClasses = {} }: ToggleProps) => {
    const theme = useTheme();

    const handleChange = useCallback(
        (newValue) => () => {
            onChange(newValue);
        },
        [onChange]
    );

    return (
        <div className={cn(classes.root, receivedClasses.root)}>
            {items.map(({ icon: Icon, label, value: itemValue }) => {
                const isSelected = value === itemValue;
                return (
                    <button
                        key={`toggle_item_${itemValue}`}
                        className={cn(classes.item, receivedClasses.item)}
                        type="button"
                        onClick={handleChange(itemValue)}
                        style={{
                            color: isSelected ? theme.palette.primary.contrastText : theme.palette.action.disabled,
                            backgroundColor: isSelected
                                ? theme.palette.primary.main
                                : theme.palette.action.disabledBackground,
                        }}
                    >
                        <Icon className={classes.icon} />
                        <Typography classes={{ root: classes.typography }} variant="button">
                            {label}
                        </Typography>
                    </button>
                );
            })}
        </div>
    );
};
