import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';

export const SUPPORTED_LANGUAGES = ['fr', 'en'];

(async () => {
    await i18n
        .use(LanguageDetector)
        .use(Backend)
        .use(initReactI18next)
        .init({
            backend: {
                loadPath: '/locales/{{lng}}/{{ns}}.json',
            },
            load: 'languageOnly',
            ns: ['landing'],
            defaultNS: 'landing',
            fallbackLng: 'fr',
            supportedLngs: SUPPORTED_LANGUAGES,
            debug: process.env.NODE_ENV === 'development',
            interpolation: {
                escapeValue: false,
                format: (value: string | Date, format?: string) => {
                    const isDate = value instanceof Date;
                    if (isDate) {
                        return moment(value).format(format);
                    }
                    if (format === 'uppercase') {
                        return value.toUpperCase();
                    }
                    return value;
                },
            },
            react: {
                useSuspense: false,
            },
        });
})();

export default i18n;
