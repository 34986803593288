import { ElementType, HTMLProps, ReactNode } from 'react';

import { CommonColors, PaletteNames } from '../../../types/theme/theme.types';
import { ClassNames } from '../../../utils/styles/styles.types';

export type TypographyVariants =
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'body1'
    | 'body2'
    | 'subtitle1'
    | 'subtitle2'
    | 'button'
    | 'caption'
    | 'overline';

export enum TypographySpecificColors {
    TextPrimary = 'textPrimary',
    TextSecondary = 'textSecondary',
}

export interface TypographyProps extends HTMLProps<'span'> {
    color?: CommonColors | PaletteNames | TypographySpecificColors;
    changeColorOnHover?: CommonColors | PaletteNames | TypographySpecificColors;
    component?: ElementType;
    variant?: TypographyVariants;
    italic?: boolean;
    uppercase?: boolean;
    classes?: ClassNames<'root'>;
    children?: ReactNode;
}
