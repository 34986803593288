import cn from 'classnames';
import * as React from 'react';
import { useMemo } from 'react';

import { getValueFromColorName } from '../../../utils/styles/getValueFromColorName';
import { useTheme } from '../../../utils/theme/theme';
import { Typography } from '../Typography/Typography';
import classes from './Divider.module.scss';
import { DividerProps } from './Divider.types';

export const Divider = ({
    children,
    backgroundColor = '#fff',
    color,
    classes: receivedClasses = {},
    style: receivedStyle,
}: DividerProps) => {
    const theme = useTheme();

    const colorValue = useMemo(
        () => getValueFromColorName({ color, theme, default: theme.components.divider.backgroundColor }),
        [color, theme]
    );

    return (
        <div
            className={cn(classes.root, receivedClasses.root)}
            style={{
                backgroundColor: colorValue,
                ...receivedStyle,
            }}
        >
            {children && (
                <Typography classes={{ root: cn(classes.text, receivedClasses?.text) }} style={{ backgroundColor }}>
                    {children}
                </Typography>
            )}
        </div>
    );
};
