import * as React from 'react';
import ReactDOM from 'react-dom';

import 'instantsearch.css/themes/algolia-min.css';
import { Application } from './components/commons/Application/Application';

import './i18n/i18n';
import './styles/global.scss';

ReactDOM.render(
    // eslint-disable-next-line react/jsx-filename-extension
    <Application />,
    document.getElementById('root')
);
