import { DocumentReference, onSnapshot } from 'firebase/firestore';
import { Action, Dispatch } from 'redux';

import { addListenerToBucket, containsListener } from '../../store/listenersBucket';

export const listenToDocumentAndDispatchActions: <DispatchAction extends Action>(values: {
    dispatch: Dispatch;
    document: DocumentReference;
    startedEventType: DispatchAction['type'];
    receivedDataEventType: DispatchAction['type'];
    receivedErrorEventType: DispatchAction['type'];
    additionalDispatchValues?: Record<string, unknown>;
    startedListeningLog?: string;
    receivedDataLog?: string;
    receivedErrorLog?: string;
}) => void = ({
    dispatch,
    document,
    startedEventType,
    receivedDataEventType,
    receivedErrorEventType,
    additionalDispatchValues = {},
    startedListeningLog,
    receivedDataLog,
    receivedErrorLog,
}) => {
    const { path } = document;

    if (containsListener(path)) {
        return;
    }

    if (startedListeningLog) {
        console.log(startedListeningLog);
    }

    dispatch({
        type: startedEventType,
        ...additionalDispatchValues,
    });

    addListenerToBucket(
        path,
        onSnapshot(
            document,
            (snapshot) => {
                if (receivedDataLog) {
                    console.log(receivedDataLog);
                }
                return dispatch({
                    type: receivedDataEventType,
                    data: snapshot.data() || null,
                    ...additionalDispatchValues,
                });
            },
            (error) => {
                if (receivedErrorLog) {
                    console.log(receivedErrorLog, { error });
                }
                return dispatch({
                    type: receivedErrorEventType,
                    error,
                    ...additionalDispatchValues,
                });
            }
        )
    );
};
