import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ReactComponent as LogoutIcon } from '../../../../../../assets/svg/logout.svg';
import { signOut } from '../../../../../../store/miscellaneous/miscellaneousActions/miscellaneousActions';
import { HeaderNestedButton } from '../../HeaderNestedActions/HeaderNestedButton/HeaderNestedButton';

export const LogOutButton = () => {
    const { t, ready } = useTranslation('header');
    const dispatch = useDispatch();
    const history = useHistory();

    const handleClick = useCallback(() => {
        dispatch(signOut());
        history.push('/');
    }, [dispatch, history]);

    if (!ready) {
        return null;
    }

    return (
        <HeaderNestedButton icon={LogoutIcon} onClick={handleClick}>
            {t('actions.signedIn.account.buttons.logOut')}
        </HeaderNestedButton>
    );
};
