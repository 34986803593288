import { combineReducers } from 'redux';

import { associationsReducer, subAssociationsReducer } from './associations/associationsReducer';
import { bookingsReducer } from './bookings/bookingsReducer';
import { categoriesReducer } from './categories/categoriesReducer';
import { eventsReducer } from './events/eventsReducer';
import { miscellaneousReducer } from './miscellaneous/miscellaneousReducer';
import { usersReducer } from './users/usersReducer';
import { waitingUsersReducer } from './waitingUsers/waitingUsersReducer';

export const combinedReducers = combineReducers({
    miscellaneous: miscellaneousReducer,
    users: usersReducer,
    waitingUsers: waitingUsersReducer,
    events: eventsReducer,
    associations: associationsReducer,
    subAssociations: subAssociationsReducer,
    bookings: bookingsReducer,
    categories: categoriesReducer,
});
