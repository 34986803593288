import { yupResolver } from '@hookform/resolvers/yup';
import { FirebaseError } from 'firebase/app';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PersonIcon } from '../../../../assets/svg/person.svg';
import { useCallbackOpen } from '../../../../hooks/useCallbackOpen';
import { PaletteNames } from '../../../../types/theme/theme.types';
import { firebaseApp } from '../../../../utils/firebase/firebase';
import { Button } from '../../../designSystem/Button/Button';
import { ButtonVariants } from '../../../designSystem/Button/Button.types';
import { TextField } from '../../../designSystem/TextField/TextField';
import { Typography } from '../../../designSystem/Typography/Typography';
import { CenterContentContainer } from '../../CenterContentContainer/CenterContentContainer';
import { FormField } from '../../FormField/FormField';
import { Loading } from '../../Loading/Loading';
import classes from './ForgotPasswordForm.module.scss';
import { getForgotPasswordSchema } from './ForgotPasswordForm.schema';
import { ForgotPasswordFormSchema } from './ForgotPasswordForm.types';

const auth = getAuth(firebaseApp);

export const ForgotPasswordForm = () => {
    const { t, ready } = useTranslation(['form', 'authenticateDialog', 'firebaseErrorMessages']);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ForgotPasswordFormSchema>({
        reValidateMode: 'onSubmit',
        resolver: yupResolver(getForgotPasswordSchema(t)),
    });

    const [globalError, setGlobalError] = useState<FirebaseError>();
    const [emailSent, setEmailSent, setEmailNotSent] = useCallbackOpen(false);
    const [loading, setIsLoading, setIsNotLoading] = useCallbackOpen(false);

    const onSubmit = handleSubmit(async (values) => {
        setIsLoading();
        setEmailNotSent();
        try {
            await sendPasswordResetEmail(auth, values.email);
            setEmailSent();
        } catch (error) {
            setGlobalError(error as FirebaseError);
        }
        setIsNotLoading();
    });

    if (loading || !ready) {
        return (
            <CenterContentContainer>
                <Loading />
            </CenterContentContainer>
        );
    }

    return (
        <form className={classes.root} {...{ onSubmit }}>
            {Boolean(globalError) && (
                <Typography classes={{ root: classes.globalError }} color={PaletteNames.Error}>
                    {t(`firebaseErrorMessages:${globalError?.code}`)}
                </Typography>
            )}
            {emailSent && (
                <Typography classes={{ root: classes.successMessage }} color={PaletteNames.Success}>
                    {t('authenticateDialog:forms.forgotPassword.emailSent')}
                </Typography>
            )}
            <FormField classes={{ root: classes.formField }} error={errors.email}>
                <TextField
                    fullWidth
                    placeholder={t('authenticateDialog:forms.forgotPassword.email.placeholder')}
                    type="email"
                    beforeInput={<PersonIcon className={classes.icon} />}
                    {...register('email')}
                />
            </FormField>
            <Button classes={{ root: classes.submit }} variant={ButtonVariants.Contained} color={PaletteNames.Primary}>
                {t('authenticateDialog:forms.forgotPassword.reset')}
            </Button>
        </form>
    );
};
