import { CommonColors, PaletteNames, Theme } from '../../types/theme/theme.types';

export const LIGHT_THEME = Object.freeze<Theme>({
    dark: false,
    palette: {
        commons: {
            [CommonColors.White]: '#fff',
            [CommonColors.Black]: '#000',
        },
        [PaletteNames.Primary]: {
            main: '#8086D2',
            contrastText: '#fff',
        },
        [PaletteNames.Error]: {
            main: '#ff0033',
            contrastText: '#fff',
        },
        [PaletteNames.Success]: {
            main: '#229f37',
            contrastText: '#fff',
        },
        background: {
            default: '#fff',
            surface: '#fff',
        },
        text: {
            primary: '#000',
            secondary: '#6F6F6F',
        },
        action: {
            disabled: 'rgba(0, 0, 0, 0.4)',
            disabledBackground: 'rgba(0, 0, 0, 0.05)',
        },
    },
    components: {
        button: {
            contained: {
                backgroundColor: '#000',
                color: '#fff',
            },
            text: {
                color: '#000',
            },
            outlined: {
                color: '#fff',
            },
        },
        divider: {
            backgroundColor: '#e5e5e5',
        },
        paper: {
            boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.05)',
        },
        textField: {
            outlined: {
                boxShadow: '0 0 1px 1px rgba(0, 0, 0, .15)',
            },
            flat: {
                backgroundColor: 'rgba(128, 134, 210, .12)',
            },
        },
    },
});
