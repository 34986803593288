import cn from 'classnames';
import React, { Fragment, useCallback } from 'react';
import { useSelector } from 'react-redux';

import i18n, { SUPPORTED_LANGUAGES } from '../../../i18n/i18n';
import { firebaseUserSelector } from '../../../store/miscellaneous/miscellaneousReducerSelectors';
import { CommonColors } from '../../../types/theme/theme.types';
import { Divider } from '../../designSystem/Divider/Divider';
import { Typography } from '../../designSystem/Typography/Typography';
import { updateRemoteLanguage } from './LanguageSelector.firebaseActions';
import classes from './LanguageSelector.module.scss';
import { LanguageSelectorProps } from './LanguageSelector.types';

export const LanguageSelector = ({ classes: receivedClasses = {} }: LanguageSelectorProps) => {
    const firebaseUser = useSelector(firebaseUserSelector);

    const handleClick = useCallback(
        (newLanguage) => async () => {
            await i18n.changeLanguage(newLanguage);
            if (firebaseUser?.uid) {
                await updateRemoteLanguage(firebaseUser?.uid, newLanguage);
            }
        },
        [firebaseUser?.uid]
    );

    return (
        <div className={cn(classes.root, receivedClasses?.root)}>
            {SUPPORTED_LANGUAGES.map((language, index) => {
                const isCurrent = language === i18n.languages?.[0];
                return (
                    <Fragment key={`language_button_${language}`}>
                        <button onClick={handleClick(language)} type="button">
                            <Typography
                                classes={{
                                    root: cn(
                                        classes.language,
                                        receivedClasses?.language,
                                        isCurrent && cn(classes.currentLanguage, receivedClasses?.currentLanguage)
                                    ),
                                }}
                                color={isCurrent ? CommonColors.White : CommonColors.Black}
                                changeColorOnHover={CommonColors.White}
                            >
                                {language}
                            </Typography>
                        </button>
                        {index < SUPPORTED_LANGUAGES.length - 1 && <Divider classes={{ root: classes.divider }} />}
                    </Fragment>
                );
            })}
        </div>
    );
};
