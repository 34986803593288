import { TypographySpecificColors } from '../../components/designSystem/Typography/Typography.types';
import { CommonColors, PaletteNames, Theme } from '../../types/theme/theme.types';

interface Options {
    theme: Theme;
    color?: CommonColors | PaletteNames | TypographySpecificColors;
    disabled?: boolean;
    default?: string;
}

type GetValueFromColorName = (options: Options) => string;

export const getValueFromColorName: GetValueFromColorName = ({
    theme,
    color,
    disabled,
    default: defaultValue = '#000',
}) => {
    if (disabled) {
        return theme.palette.action.disabled;
    }
    if (Object.values(CommonColors).includes(color as CommonColors)) {
        return theme.palette.commons[color as CommonColors];
    }
    if (Object.values(TypographySpecificColors).includes(color as TypographySpecificColors)) {
        const key = color === TypographySpecificColors.TextPrimary ? 'primary' : 'secondary';
        return theme.palette.text[key];
    }
    if (theme.palette[color as PaletteNames]?.main) {
        return theme.palette[color as PaletteNames].main;
    }
    return defaultValue;
};
