import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ReactComponent as AccountCircleIcon } from '../../../../../assets/svg/account-circle.svg';
import { ReactComponent as DashboardIcon } from '../../../../../assets/svg/dashboard.svg';
import { useCurrentUser } from '../../../../../hooks/fetchers/users/useCurrentUser';
import { HeaderNestedActions } from '../HeaderNestedActions/HeaderNestedActions';
import { HeaderNestedButton } from '../HeaderNestedActions/HeaderNestedButton/HeaderNestedButton';
import { LogOutButton } from './LogOutButton/LogOutButton';
import { RolesButtons } from './RolesButtons/RolesButtons';

export const SignedInActions = () => {
    const { t, ready } = useTranslation('header');
    const { user } = useCurrentUser();

    if (!ready) {
        return null;
    }

    return (
        <>
            <RolesButtons />
            <HeaderNestedActions icon={AccountCircleIcon} label={t('actions.signedIn.account.label')}>
                {Boolean(user) && !user?.isWaiting && (
                    <Link to="/dashboard">
                        <HeaderNestedButton icon={DashboardIcon}>
                            {t('actions.signedIn.account.buttons.dashboard')}
                        </HeaderNestedButton>
                    </Link>
                )}
                <LogOutButton />
            </HeaderNestedActions>
        </>
    );
};
