import cn from 'classnames';
import * as React from 'react';

import classes from './Logo.module.scss';
import { LogoProps } from './Logo.types';

export const Logo = ({ classes: receivedClasses = {}, withoutText = false }: LogoProps) => (
    <img
        className={cn(classes.root, receivedClasses.root)}
        src={`/assets/png/logo-white${withoutText ? '-without-text' : ''}.png`}
        alt="Logo"
    />
);
