import { createUserWithEmailAndPassword, getAuth, sendEmailVerification } from 'firebase/auth';
import { doc, setDoc, getFirestore, serverTimestamp } from 'firebase/firestore';

import { RegisterFormSchema } from '../../components/commons/AuthenticateDialog/RegisterForm/RegisterForm.types';
import i18n from '../../i18n/i18n';
import { RolesEnum } from '../../types/firestore/users/users.types';
import { WaitingUser } from '../../types/firestore/waitingUsers/waitingUsers.types';
import { firebaseApp } from './firebase';
import { SubAssociationUserOnboardingSchema } from '../../components/routesComponents/Dashboard/routesComponents/CreateSubAssociation/RegisterSubAssociationForm.types';
import { getFunctions, httpsCallable } from 'firebase/functions';

const firestore = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

// Create the users' document in the "waitingUsers" collection.
export const createWaitingUserDocument = (
    uid: string,
    role?: RolesEnum,
    defaultData?: Partial<WaitingUser['data']>
) => {
    console.log('[registerUser][createWaitingUserDocument] Creating users document...');
    const waitingUserDocument = doc(firestore, `/waitingUsers/${uid}`);

    return setDoc(waitingUserDocument, {
        ...JSON.parse(
            JSON.stringify({
                role,
                data: defaultData,
                hasFinishedOnboarding: false,
            })
        ),
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
    });
};

// Create the users' document in the "users" collection.
export const createUserDocument = (uid: string) => {
    console.log('[registerUser][createUserDocument] Creating users document...');
    const document = doc(firestore, `/users/${uid}`);
    return setDoc(document, {
        isWaiting: true,
        language: i18n.languages?.[0],
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
    });
};

interface Parameters {
    uid: string;
    role?: RolesEnum;
    defaultWaitingData?: Partial<WaitingUser['data']>;
}

export const bootstrapNewUser = async ({ uid, role, defaultWaitingData }: Parameters) => {
    await createUserDocument(uid);
    await createWaitingUserDocument(uid, role as RolesEnum, defaultWaitingData);
};

// Create Firebase account and call `createWaitingUserDocument`.
export const registerUser = async (data: RegisterFormSchema) => {
    console.log('[registerUser] Creating users account...');
    const result = await createUserWithEmailAndPassword(auth, data.email, data.password);
    console.log('[registerUser] User successfully created!');
    const { user: firebaseUser } = result || {};
    if (!firebaseUser) {
        return null;
    }
    console.log('[registerUser] Sending email verification...');
    await sendEmailVerification(firebaseUser);
    const { uid } = firebaseUser;
    return bootstrapNewUser({
        uid,
        role: data.role as RolesEnum,
        defaultWaitingData: {
            email: data.email,
        },
    });
};

export const registerAssociation = async (data: SubAssociationUserOnboardingSchema, uid: any) => {
    const associationData = {
        userAuth: {
            email: data.email,
            password: data.password,
        },
        user: {
            language: i18n.languages?.[0],
        },
        waitingUser: {
            ...data,
            createdBy: uid,
        },
    };

    const functions = getFunctions(firebaseApp);

    const PREFIX = '[createAssociationUser]';

    console.log(PREFIX, 'creating association user...');
    const createAssociation = httpsCallable(functions, 'createSubAssociation');
    return createAssociation(associationData);
};
