import { FC, useEffect, useMemo } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch, useLocation } from 'react-router-dom';

import { useCurrentUser } from '../../../hooks/fetchers/users/useCurrentUser';
import { useWaitingUser } from '../../../hooks/fetchers/waitingUsers/useWaitingUser';
import i18n from '../../../i18n/i18n';
import { listenForAuthStateChange } from '../../../store/miscellaneous/miscellaneousActions/miscellaneousActions';
import { firebaseUserSelector } from '../../../store/miscellaneous/miscellaneousReducerSelectors';
import { useTheme } from '../../../utils/theme/theme';
import { COMMONS_ROUTES } from '../commons.routes';
import { ONBOARDING_ROUTES } from '../onboarding.routes';
import { SIGNED_IN_ROUTES } from '../signedIn.routes';
import { VERIFY_EMAIL_ROUTES } from '../verifyEmail.routes';
import { WAITING_FOR_VERIFICATION_ROUTES } from '../waitingForVerification.routes';
import { EMAIL_VERIFICATION_ROUTES } from '../emailVerification.routes';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const Routes: FC = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const firebaseUser = useSelector(firebaseUserSelector);
    const { user } = useCurrentUser();
    const { waitingUser } = useWaitingUser(firebaseUser?.uid, { enabled: Boolean(user?.isWaiting) });

    const { language: remoteLanguage } = user || {};
    const query = useQuery();
    const mode = query.get('mode');
    console.log(mode);

    const routes = useMemo(() => {
        // if mode present then its a redirect from firebase email template
        if (mode && !firebaseUser?.emailVerified) {
            return EMAIL_VERIFICATION_ROUTES;
        }
        // User connected but did not verify email address.
        if (firebaseUser?.uid && !firebaseUser?.emailVerified) {
            return VERIFY_EMAIL_ROUTES;
        }

        if (!firebaseUser?.uid || !user || (user?.isWaiting && !waitingUser)) {
            // Only show common routes.
            return COMMONS_ROUTES;
        }

        // User has submitted onboarding data. Waiting for verification.
        if (user?.isWaiting && waitingUser?.hasFinishedOnboarding) {
            return WAITING_FOR_VERIFICATION_ROUTES;
        }

        // User has not submitted onboarding data. Showing onboarding.
        if (user?.isWaiting) {
            return ONBOARDING_ROUTES;
        }
        // User is signed in, finished onboarding & has been verified.
        return [...SIGNED_IN_ROUTES, ...COMMONS_ROUTES];
    }, [firebaseUser, user, waitingUser, mode]);

    useEffect(() => {
        dispatch(listenForAuthStateChange());
    }, [dispatch]);

    useEffect(() => {
        if (i18n.language !== remoteLanguage && remoteLanguage) {
            (async () => {
                await i18n.changeLanguage(remoteLanguage);
            })();
        }
    }, [remoteLanguage]);

    useEffect(() => {
        document.body.style.backgroundColor = theme.palette.background.default;
    }, [theme]);

    return (
        <>
            <Switch>
                {routes}
                <Redirect from="*" to="/" />
            </Switch>
        </>
    );
};
