import cn from 'classnames';
import * as React from 'react';

import { Button } from '../../../../../designSystem/Button/Button';
import classes from './HeaderNestedButton.module.scss';
import { HeaderNestedButtonProps } from './HeaderNestedButton.types';

export const HeaderNestedButton = ({
    icon: Icon,
    classes: receivedClasses = {},
    children,
    ...other
}: HeaderNestedButtonProps) => (
    <Button classes={{ root: cn(classes.root, receivedClasses.root) }} {...other}>
        <Icon className={classes.icon} />
        {children}
    </Button>
);
