import { Query, QueryConstraint, CollectionReference, query, onSnapshot } from 'firebase/firestore';
import { Action, Dispatch } from 'redux';

import { addListenerToBucket, containsListener } from '../../store/listenersBucket';
import { getObjectFromCollectionSnapshot } from './getObjectFromCollectionSnapshot';

export const listenToCollectionAndDispatchActions: <DispatchAction extends Action>(values: {
    dispatch: Dispatch;
    collection: CollectionReference;
    startedEventType: DispatchAction['type'];
    receivedDataEventType: DispatchAction['type'];
    receivedErrorEventType: DispatchAction['type'];
    queries?: QueryConstraint[];
    additionalDispatchValues?: Record<string, unknown>;
    startedListeningLog?: string;
    receivedDataLog?: string;
    receivedErrorLog?: string;
}) => void = ({
    dispatch,
    collection,
    startedEventType,
    receivedDataEventType,
    receivedErrorEventType,
    additionalDispatchValues = {},
    queries,
    startedListeningLog,
    receivedDataLog,
    receivedErrorLog,
}) => {
    const listenerBucketKey = [collection.path, queries];

    if (containsListener(listenerBucketKey)) {
        return;
    }

    if (startedListeningLog) {
        console.log(startedListeningLog);
    }

    dispatch({
        type: startedEventType,
        ...additionalDispatchValues,
    });

    let collectionQuery: CollectionReference | Query;

    if (queries) {
        collectionQuery = queries.reduce((acc, qc) => query(collection, qc), collection as typeof collectionQuery);
    } else {
        collectionQuery = collection;
    }

    addListenerToBucket(
        listenerBucketKey,
        onSnapshot(
            collectionQuery,
            (snapshot) => {
                if (receivedDataLog) {
                    console.log(receivedDataLog);
                }
                return dispatch({
                    type: receivedDataEventType,
                    data: getObjectFromCollectionSnapshot(snapshot),
                    ...additionalDispatchValues,
                });
            },
            (error) => {
                if (receivedErrorLog) {
                    console.log(receivedErrorLog, { error });
                }
                return dispatch({
                    type: receivedErrorEventType,
                    error,
                    ...additionalDispatchValues,
                });
            }
        )
    );
};
