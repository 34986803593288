import { getAuth, signInWithPopup } from 'firebase/auth';
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore';

import { RolesEnum } from '../../../../types/firestore/users/users.types';
import { firebaseApp } from '../../../../utils/firebase/firebase';
import { bootstrapNewUser } from '../../../../utils/firebase/registerUser';
import { SOCIAL_PROVIDERS_BUTTONS_DATA } from './SocialProvidersButtons.data';
import { SocialProviders } from './SocialProvidersButtons.types';

const PREFIX = '[handleSignInWithPopup]';

const firestore = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

export const handleSignInWithPopup = async (socialProvider: SocialProviders) => {
    console.log(PREFIX, 'Handling sign in with popup...');
    const { authProvider } = SOCIAL_PROVIDERS_BUTTONS_DATA[socialProvider];
    if (authProvider) {
        console.log(PREFIX, 'Signing in with provider...');
        const userCredential = await signInWithPopup(auth, authProvider);
        console.log(PREFIX, 'Sign in successful, checking if users must be bootstrapped.');

        const { user } = userCredential;
        const { uid, providerData, email } = user;

        const existingUserDocument = doc(firestore, `/users/${uid}`);
        const existingUser = await getDoc(existingUserDocument);
        if (!existingUser.exists()) {
            await bootstrapNewUser({
                uid,
                role: RolesEnum.RegularUser,
                defaultWaitingData: {
                    phoneNumber: providerData?.[0]?.phoneNumber as string,
                    ...(Boolean(email) && email && { email }),
                },
            });
        }
        return updateDoc(existingUserDocument, {
            providerData,
        });
    }

    return null;
};
