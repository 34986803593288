export interface PaletteColor {
    main: string;
    contrastText: string;
}

export enum PaletteNames {
    Primary = 'primary',
    Error = 'error',
    Success = 'success',
}

export enum CommonColors {
    White = 'white',
    Black = 'black',
}

export type Palette = {
    [paletteName in PaletteNames]: PaletteColor;
} & {
    commons: {
        [colorName in CommonColors]: string;
    };
    background: {
        default: string;
        surface: string;
    };
    text: {
        primary: string;
        secondary: string;
    };
    action: {
        disabled: string;
        disabledBackground: string;
    };
};

export interface Components {
    button: {
        contained: {
            backgroundColor: string;
            color: string;
        };
        text: {
            color: string;
        };
        outlined: {
            color: string;
        };
    };
    divider: {
        backgroundColor: string;
    };
    paper: {
        boxShadow: string;
    };
    textField: {
        outlined: {
            boxShadow: string;
        };
        flat: {
            backgroundColor: string;
        };
    };
}

export interface Theme {
    dark: boolean;
    palette: Palette;
    components: Components;
}
