import cn from 'classnames';
import * as React from 'react';

import { useTheme } from '../../../../../../utils/theme/theme';
import { Divider } from '../../../../../designSystem/Divider/Divider';
import { Typography } from '../../../../../designSystem/Typography/Typography';
import { HeaderNestedActionsProps } from '../HeaderNestedActions.types';
import classes from './InDrawerNestedActions.module.scss';

export const InDrawerNestedActions = ({
    icon: Icon,
    label,
    classes: receivedClasses = {},
    children,
}: HeaderNestedActionsProps) => {
    const { palette } = useTheme();
    return (
        <div className={cn(classes.root, receivedClasses.root)}>
            <Divider
                classes={{ root: classes.header, text: classes.headerText }}
                backgroundColor={palette.background.default}
            >
                <Icon className={classes.icon} style={{ fill: palette.text.primary }} />
                <Typography variant="body2">{label}</Typography>
            </Divider>
            {children}
        </div>
    );
};
