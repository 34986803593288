import { Action } from 'redux';

import { WaitingUser, WaitingUsers } from '../../../types/firestore/waitingUsers/waitingUsers.types';
import { SignOutAction } from '../../miscellaneous/miscellaneousActions/miscellaneousActions.types';

export const LISTEN_TO_WAITING_USER_STARTED = 'LISTEN_TO_WAITING_USER_STARTED';
export const LISTEN_TO_WAITING_USER_RECEIVED_DATA = 'LISTEN_TO_WAITING_USER_RECEIVED_DATA';
export const LISTEN_TO_WAITING_USER_RECEIVED_ERROR = 'LISTEN_TO_WAITING_USER_RECEIVED_ERROR';

export const LISTEN_TO_ALL_WAITING_USERS_STARTED = 'LISTEN_TO_ALL_WAITING_USERS_STARTED';
export const LISTEN_TO_ALL_WAITING_USERS_RECEIVED_DATA = 'LISTEN_TO_ALL_WAITING_USERS_RECEIVED_DATA';
export const LISTEN_TO_ALL_WAITING_USERS_RECEIVED_ERROR = 'LISTEN_TO_ALL_WAITING_USERS_RECEIVED_ERROR';

export interface ListenToWaitingUserStartedAction extends Action {
    type: typeof LISTEN_TO_WAITING_USER_STARTED;
    userId: string;
}

export interface ListenToWaitingUserReceivedDataAction extends Action {
    type: typeof LISTEN_TO_WAITING_USER_RECEIVED_DATA;
    userId: string;
    data: WaitingUser;
}

export interface ListenToWaitingUserReceivedErrorAction extends Action {
    type: typeof LISTEN_TO_WAITING_USER_RECEIVED_ERROR;
    userId: string;
    error: Error;
}

export interface ListenToAllWaitingUsersStartedAction extends Action {
    type: typeof LISTEN_TO_ALL_WAITING_USERS_STARTED;
}

export interface ListenToAllWaitingUsersReceivedDataAction extends Action {
    type: typeof LISTEN_TO_ALL_WAITING_USERS_RECEIVED_DATA;
    data: WaitingUsers;
}

export interface ListenToAllWaitingUserReceivedErrorAction extends Action {
    type: typeof LISTEN_TO_ALL_WAITING_USERS_RECEIVED_ERROR;
    error: Error;
}

export type WaitingUsersReducerActionTypes =
    | ListenToWaitingUserStartedAction
    | ListenToWaitingUserReceivedDataAction
    | ListenToWaitingUserReceivedErrorAction
    | ListenToAllWaitingUsersStartedAction
    | ListenToAllWaitingUsersReceivedDataAction
    | ListenToAllWaitingUserReceivedErrorAction
    | SignOutAction;
