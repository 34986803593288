import { Reducer } from 'redux';

import { SIGN_OUT } from '../miscellaneous/miscellaneousActions/miscellaneousActions.types';
import {
    LISTEN_TO_ALL_WAITING_USERS_RECEIVED_DATA,
    LISTEN_TO_ALL_WAITING_USERS_RECEIVED_ERROR,
    LISTEN_TO_ALL_WAITING_USERS_STARTED,
    LISTEN_TO_WAITING_USER_RECEIVED_DATA,
    LISTEN_TO_WAITING_USER_RECEIVED_ERROR,
    LISTEN_TO_WAITING_USER_STARTED,
    WaitingUsersReducerActionTypes,
} from './waitingUsersActions/waitingUsersActions.types';
import { WaitingUsersReducerState } from './waitingUsersReducer.types';
import { WAITING_USERS_REDUCER_INITIAL_STATE } from './waitingUsersReducerInitialState';

export const waitingUsersReducer: Reducer<WaitingUsersReducerState, WaitingUsersReducerActionTypes> = (
    state = WAITING_USERS_REDUCER_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case LISTEN_TO_WAITING_USER_STARTED:
            return {
                ...state,
                hasReceivedWaitingUsers: {
                    ...state.hasReceivedWaitingUsers,
                    [action.userId]: null,
                },
            };

        case LISTEN_TO_WAITING_USER_RECEIVED_DATA:
            return {
                ...state,
                hasReceivedWaitingUsers: {
                    ...state.hasReceivedWaitingUsers,
                    [action.userId]: true,
                },
                waitingUsers: {
                    ...state.waitingUsers,
                    [action.userId]: action.data,
                },
            };

        case LISTEN_TO_WAITING_USER_RECEIVED_ERROR:
            return {
                ...state,
                hasReceivedWaitingUsers: {
                    ...state.hasReceivedWaitingUsers,
                    [action.userId]: action.error,
                },
            };

        case LISTEN_TO_ALL_WAITING_USERS_STARTED:
            return {
                ...state,
                hasReceivedAllWaitingUsers: null,
            };

        case LISTEN_TO_ALL_WAITING_USERS_RECEIVED_DATA:
            return {
                ...state,
                hasReceivedAllWaitingUsers: true,
                waitingUsers: action.data,
                hasReceivedWaitingUsers: Object.keys(action.data).reduce(
                    (acc, waitingUserId) => ({
                        ...acc,
                        [waitingUserId]: true,
                    }),
                    {} as WaitingUsersReducerState['hasReceivedWaitingUsers']
                ),
            };

        case LISTEN_TO_ALL_WAITING_USERS_RECEIVED_ERROR:
            return {
                ...state,
                hasReceivedAllWaitingUsers: action.error,
            };

        case SIGN_OUT:
            return WAITING_USERS_REDUCER_INITIAL_STATE;

        default:
            return state;
    }
};
