import { config } from '@react-spring/web';

export const DIALOG_TRANSITIONS_SPRING_PROPS = {
    from: {
        opacity: 0,
    },
    enter: {
        opacity: 1,
    },
    leave: {
        opacity: 0,
    },
    key: (item: boolean) => `${item ? 'visible' : 'invisible'}_dialog`,
    unique: true,
    config: config.stiff,
};
