import { CommonColors, PaletteNames, Theme } from '../../types/theme/theme.types';

export const DARK_THEME = Object.freeze<Theme>({
    dark: true,
    palette: {
        commons: {
            [CommonColors.White]: '#fff',
            [CommonColors.Black]: '#000',
        },
        [PaletteNames.Primary]: {
            main: '#8086D2',
            contrastText: '#fff',
        },
        [PaletteNames.Error]: {
            main: '#ff0033',
            contrastText: '#fff',
        },
        [PaletteNames.Success]: {
            main: '#229f37',
            contrastText: '#fff',
        },
        background: {
            default: 'rgb(128 134 210)',
            surface: 'rgb(128 134 210)',
        },
        text: {
            primary: '#fff',
            secondary: '#fff',
        },
        action: {
            disabled: 'rgba(255, 255, 255, 0.3)',
            disabledBackground: 'rgba(255, 255, 255, 0.12)',
        },
    },
    components: {
        button: {
            contained: {
                backgroundColor: '#fff',
                color: '#000',
            },
            text: {
                color: '#fff',
            },
            outlined: {
                color: '#fff',
            },
        },
        divider: {
            backgroundColor: '#fff',
        },
        paper: {
            boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.1)',
        },
        textField: {
            flat: {
                backgroundColor: 'rgba(128, 134, 210, .12)',
            },
            outlined: {
                boxShadow: '0 0 2px #fff',
            },
        },
    },
});
