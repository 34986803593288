import { yupResolver } from '@hookform/resolvers/yup';
import { FirebaseError } from 'firebase/app';
import * as React from 'react';
import { useContext, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LockIcon } from '../../../../assets/svg/lock.svg';
import { ReactComponent as PersonIcon } from '../../../../assets/svg/person.svg';
import { useCallbackOpen } from '../../../../hooks/useCallbackOpen';
import { RolesEnum } from '../../../../types/firestore/users/users.types';
import { PaletteNames } from '../../../../types/theme/theme.types';
import { registerUser } from '../../../../utils/firebase/registerUser';
import { Button } from '../../../designSystem/Button/Button';
import { ButtonVariants } from '../../../designSystem/Button/Button.types';
import { TextField } from '../../../designSystem/TextField/TextField';
import { Toggle } from '../../../designSystem/Toggle/Toggle';
import { ToggleItem } from '../../../designSystem/Toggle/Toggle.types';
import { Typography } from '../../../designSystem/Typography/Typography';
import { CenterContentContainer } from '../../CenterContentContainer/CenterContentContainer';
import { FormField } from '../../FormField/FormField';
import { Loading } from '../../Loading/Loading';
import { AuthenticateDialogContext } from '../AuthenticateDialog.context';
import { REGISTER_FORM_ROLES_BUTTONS_DATA } from './RegisterForm.data';
import classes from './RegisterForm.module.scss';
import { getRegisterSchema } from './RegisterForm.schema';
import { RegisterFormSchema } from './RegisterForm.types';

export const RegisterForm = () => {
    const { onClose } = useContext(AuthenticateDialogContext);
    const { t, ready } = useTranslation(['form', 'authenticateDialog', 'firebaseErrorMessages', 'roles']);
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm<RegisterFormSchema>({
        reValidateMode: 'onSubmit',
        resolver: yupResolver(getRegisterSchema(t)),
        defaultValues: {
            role: RolesEnum.RegularUser,
        },
    });

    const [globalError, setGlobalError] = useState<FirebaseError | Error>();
    const [loading, setIsLoading, setIsNotLoading] = useCallbackOpen(false);

    const onSubmit = handleSubmit(async (values) => {
        setIsLoading();
        try {
            await registerUser(values);
            onClose();
        } catch (error) {
            setGlobalError(error as Error);
        }
        setIsNotLoading();
    });

    const rolesToggleItems: ToggleItem[] = useMemo(
        () =>
            Object.entries(REGISTER_FORM_ROLES_BUTTONS_DATA).map(([roleId, { icon }]) => ({
                value: roleId,
                label: ready ? t(`roles:${roleId}`) : '',
                icon,
            })),
        [t, ready]
    );

    if (loading || !ready) {
        return (
            <CenterContentContainer>
                <Loading />
            </CenterContentContainer>
        );
    }

    return (
        <form className={classes.root} {...{ onSubmit }}>
            {Boolean(globalError) && (
                <Typography classes={{ root: classes.globalError }} color={PaletteNames.Error}>
                    {(globalError as FirebaseError)?.code
                        ? t(`firebaseErrorMessages:${(globalError as FirebaseError)?.code}`)
                        : (globalError as Error).message}
                </Typography>
            )}
            <Controller
                name="role"
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <FormField classes={{ root: classes.formField }} {...{ error }}>
                        <Toggle
                            classes={{ root: classes.toggle, item: classes.toggleItem }}
                            items={rolesToggleItems}
                            {...{ value, onChange }}
                        />
                    </FormField>
                )}
                {...{ control }}
            />
            <FormField classes={{ root: classes.formField }} error={errors.email}>
                <TextField
                    fullWidth
                    placeholder={t('authenticateDialog:forms.register.email.placeholder')}
                    type="email"
                    beforeInput={<PersonIcon className={classes.icon} />}
                    {...register('email')}
                />
            </FormField>
            <FormField classes={{ root: classes.formField }} error={errors.password}>
                <TextField
                    fullWidth
                    placeholder={t('authenticateDialog:forms.register.password.placeholder')}
                    type="password"
                    beforeInput={<LockIcon className={classes.icon} />}
                    {...register('password')}
                />
            </FormField>
            <Button classes={{ root: classes.submit }} variant={ButtonVariants.Contained} color={PaletteNames.Primary}>
                {t('authenticateDialog:forms.register.createAccount')}
            </Button>
        </form>
    );
};
