import * as React from 'react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ThemeProvider } from '../../../utils/theme/theme';
import { Dialog } from '../../designSystem/Dialog/Dialog';
import { DialogCloseIcon } from '../../designSystem/DialogCloseIcon/DialogCloseIcon';
import { DialogContent } from '../../designSystem/DialogContent/DialogContent';
import { DialogTitle } from '../../designSystem/DialogTitle/DialogTitle';
import { Divider } from '../../designSystem/Divider/Divider';
import { AuthenticateDialogContext } from './AuthenticateDialog.context';
import classes from './AuthenticateDialog.module.scss';
import { AuthenticateDialogProps, AuthenticationTypes, FormProps } from './AuthenticateDialog.types';
import { ForgotPasswordForm } from './ForgotPasswordForm/ForgotPasswordForm';
import { RegisterForm } from './RegisterForm/RegisterForm';
import { SignInForm } from './SignInForm/SignInForm';
import { SocialProvidersButtons } from './SocialProvidersButtons/SocialProvidersButtons';
import { TypeToggle } from './TypeToggle/TypeToggle';

const Form = ({ currentType }: FormProps) => {
    switch (currentType) {
        case AuthenticationTypes.SignIn:
            return <SignInForm />;
        case AuthenticationTypes.Register:
            return <RegisterForm />;
        case AuthenticationTypes.ForgotPassword:
            return <ForgotPasswordForm />;
        default:
            return null;
    }
};

export const AuthenticateDialog = ({ open, onClose }: AuthenticateDialogProps) => {
    const [currentType, setCurrentType] = useState<AuthenticationTypes>(AuthenticationTypes.SignIn);
    const { t, ready } = useTranslation('authenticateDialog');

    const contextValue = useMemo(
        () => ({
            currentType,
            setCurrentType,
            onClose,
        }),
        [currentType, setCurrentType, onClose]
    );

    if (!ready) {
        return null;
    }

    return (
        <ThemeProvider>
            <Dialog
                classes={{ paper: classes.paper }}
                {...{
                    open,
                    onClose,
                }}
            >
                <DialogTitle>
                    {t('title')}
                    <DialogCloseIcon {...{ onClose }} />
                </DialogTitle>
                <DialogContent>
                    <TypeToggle classes={{ root: classes.typeToggle }} {...{ currentType, setCurrentType }} />
                    <AuthenticateDialogContext.Provider value={contextValue}>
                        <Form {...{ currentType }} />
                    </AuthenticateDialogContext.Provider>
                    <Divider classes={{ root: classes.signInWithProvidersDivider }}>{t('signInWithProvider')}</Divider>
                    <SocialProvidersButtons {...{ onClose }} />
                </DialogContent>
            </Dialog>
        </ThemeProvider>
    );
};
