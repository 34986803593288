import { RootState } from '../store.types';

export const isWaitingAuthStateChangeSelector = ({ miscellaneous: reducer }: RootState) =>
    reducer.isWaitingForAuthStateChange;

export const firebaseUserSelector = ({ miscellaneous: reducer }: RootState) => reducer.firebaseUser;

export const isHeaderTransparentSelector = ({ miscellaneous: reducer }: RootState) => reducer.isHeaderTransparent;

export const isPersistentDrawerOpenedSelector = ({ miscellaneous: reducer }: RootState) =>
    reducer.isPersistentDrawerOpened;
