import { GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider } from 'firebase/auth';

import { ReactComponent as FacebookIcon } from '../../../../assets/svg/facebook.svg';
import { ReactComponent as GoogleIcon } from '../../../../assets/svg/google.svg';
import { ReactComponent as TwitterIcon } from '../../../../assets/svg/twitter.svg';
import { SocialProviders, SocialProvidersButtonsData } from './SocialProvidersButtons.types';

export const SOCIAL_PROVIDERS_BUTTONS_DATA = Object.freeze<SocialProvidersButtonsData>({
    [SocialProviders.Facebook]: {
        icon: FacebookIcon,
        name: 'Facebook',
        color: '#4267B2',
        authProvider: new FacebookAuthProvider(),
    },
    [SocialProviders.Google]: {
        icon: GoogleIcon,
        name: 'Google',
        color: '#DB4437',
        authProvider: new GoogleAuthProvider(),
    },
    [SocialProviders.Twitter]: {
        icon: TwitterIcon,
        name: 'Twitter',
        color: '#1DA1F2',
        authProvider: new TwitterAuthProvider(),
    },
});
