import { Action } from 'redux';

import { Event, Events } from '../../../types/firestore/events/events.types';
import { SignOutAction } from '../../miscellaneous/miscellaneousActions/miscellaneousActions.types';

export const LISTEN_TO_EVENT_STARTED = 'LISTEN_TO_EVENT_STARTED';
export const LISTEN_TO_EVENT_RECEIVED_DATA = 'LISTEN_TO_EVENT_RECEIVED_DATA';
export const LISTEN_TO_EVENT_RECEIVED_ERROR = 'LISTEN_TO_EVENT_RECEIVED_ERROR';

export const LISTEN_TO_ALL_EVENTS_BY_ASSOCIATION_ID_STARTED = 'LISTEN_TO_ALL_EVENTS_BY_ASSOCIATION_ID_STARTED';
export const LISTEN_TO_ALL_EVENTS_BY_ASSOCIATION_ID_RECEIVED_DATA =
    'LISTEN_TO_ALL_EVENTS_BY_ASSOCIATION_ID_RECEIVED_DATA';
export const LISTEN_TO_ALL_EVENTS_BY_ASSOCIATION_ID_RECEIVED_ERROR =
    'LISTEN_TO_ALL_EVENTS_BY_ASSOCIATION_ID_RECEIVED_ERROR';

export interface ListenToEventStartedAction extends Action {
    type: typeof LISTEN_TO_EVENT_STARTED;
    eventId: string;
}

export interface ListenToEventReceivedDataAction extends Action {
    type: typeof LISTEN_TO_EVENT_RECEIVED_DATA;
    eventId: string;
    data: Event;
}

export interface ListenToEventReceivedErrorAction extends Action {
    type: typeof LISTEN_TO_EVENT_RECEIVED_ERROR;
    eventId: string;
    error: Error;
}

export interface ListenToAllEventsByAssociationIdStartedAction extends Action {
    type: typeof LISTEN_TO_ALL_EVENTS_BY_ASSOCIATION_ID_STARTED;
    associationId: string;
}

export interface ListenToAllEventsByAssociationIdReceivedDataAction extends Action {
    type: typeof LISTEN_TO_ALL_EVENTS_BY_ASSOCIATION_ID_RECEIVED_DATA;
    associationId: string;
    data: Events;
}

export interface ListenToAllEventsByAssociationIdReceivedErrorAction extends Action {
    type: typeof LISTEN_TO_ALL_EVENTS_BY_ASSOCIATION_ID_RECEIVED_ERROR;
    associationId: string;
    error: Error;
}

export type EventsReducerActionTypes =
    | ListenToEventStartedAction
    | ListenToEventReceivedDataAction
    | ListenToEventReceivedErrorAction
    | ListenToAllEventsByAssociationIdStartedAction
    | ListenToAllEventsByAssociationIdReceivedDataAction
    | ListenToAllEventsByAssociationIdReceivedErrorAction
    | SignOutAction;
