import { animated, useTransition } from '@react-spring/web';
import { Children, cloneElement, ReactElement, useCallback, useState } from 'react';
import * as React from 'react';
import { usePopper } from 'react-popper';
import { Portal } from 'react-portal';

import { CommonColors } from '../../../types/theme/theme.types';
import { Typography } from '../Typography/Typography';
import classes from './Tooltip.module.scss';
import { getTooltipTransitionsSpringProps } from './Tooltip.springProps';
import { TooltipProps } from './Tooltip.types';

const rootElement = document.getElementById('root');

export const Tooltip = ({ placement = 'top', content, children }: TooltipProps) => {
    const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>();
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();

    const { styles, attributes, state } = usePopper(referenceElement, popperElement, {
        placement,
        modifiers: [
            {
                name: 'computeStyles',
                options: {
                    gpuAcceleration: false,
                },
            },
        ],
    });

    const { placement: popperActualPlacement } = state || {};

    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    const handleFocus = useCallback(
        (onMouseEnter: () => void) => () => {
            if (typeof onMouseEnter === 'function') {
                onMouseEnter();
            }
            setShowTooltip(true);
        },
        []
    );
    const handleBlur = useCallback(
        (onMouseLeave: () => void) => () => {
            if (typeof onMouseLeave === 'function') {
                onMouseLeave();
            }
            setShowTooltip(false);
        },
        []
    );

    const transitions = useTransition(
        showTooltip,
        getTooltipTransitionsSpringProps((popperActualPlacement as 'top' | 'right' | 'bottom' | 'left') || placement)
    );

    if (!children) {
        return null;
    }

    return (
        <>
            {Children.map(children, (child) =>
                cloneElement(child as ReactElement, {
                    ref: setReferenceElement,
                    onMouseEnter: handleFocus((child as ReactElement)?.props?.onMouseEnter),
                    onMouseLeave: handleBlur((child as ReactElement)?.props?.onMouseLeave),
                })
            )}
            <Portal node={rootElement}>
                {transitions(
                    (props, item) =>
                        item && (
                            <animated.div
                                ref={setPopperElement}
                                className={classes.root}
                                style={{
                                    ...styles.popper,
                                    ...props,
                                }}
                                {...attributes.popper}
                            >
                                <Typography classes={{ root: classes.typography }} color={CommonColors.White}>
                                    {content}
                                </Typography>
                            </animated.div>
                        )
                )}
            </Portal>
        </>
    );
};
