import { doc, getFirestore } from 'firebase/firestore';
import { Dispatch } from 'redux';

import { listenToDocumentAndDispatchActions } from '../../../utils/actions/listenToDocumentAndDispatchActions';
import { firebaseApp } from '../../../utils/firebase/firebase';
import {
    LISTEN_TO_USER_RECEIVED_DATA,
    LISTEN_TO_USER_RECEIVED_ERROR,
    LISTEN_TO_USER_STARTED,
    ListenToUserReceivedDataAction,
    ListenToUserReceivedErrorAction,
    ListenToUserStartedAction,
} from './usersActions.types';

const firestore = getFirestore(firebaseApp);

export const listenToUser = (userId: string) => (dispatch: Dispatch) =>
    listenToDocumentAndDispatchActions<
        ListenToUserStartedAction | ListenToUserReceivedDataAction | ListenToUserReceivedErrorAction
    >({
        dispatch,
        document: doc(firestore, `/users/${userId}`),
        startedEventType: LISTEN_TO_USER_STARTED,
        receivedDataEventType: LISTEN_TO_USER_RECEIVED_DATA,
        receivedErrorEventType: LISTEN_TO_USER_RECEIVED_ERROR,
        startedListeningLog: `[User Actions][listenToUser] Listening to user ${userId}...`,
        receivedDataLog: '[Users Actions][listenToUser] Received data.',
        receivedErrorLog: '[Users Actions][listenToUser] Received error.',
        additionalDispatchValues: {
            userId,
        },
    });
