import { Action } from 'redux';

import { User } from '../../../types/firestore/users/users.types';
import { SignOutAction } from '../../miscellaneous/miscellaneousActions/miscellaneousActions.types';

export const LISTEN_TO_USER_STARTED = 'LISTEN_TO_USER_STARTED';
export const LISTEN_TO_USER_RECEIVED_DATA = 'LISTEN_TO_USER_RECEIVED_DATA';
export const LISTEN_TO_USER_RECEIVED_ERROR = 'LISTEN_TO_ERROR_RECEIVED_ERROR';

export interface ListenToUserStartedAction extends Action {
    type: typeof LISTEN_TO_USER_STARTED;
    userId: string;
}

export interface ListenToUserReceivedDataAction extends Action {
    type: typeof LISTEN_TO_USER_RECEIVED_DATA;
    userId: string;
    data: User;
}

export interface ListenToUserReceivedErrorAction extends Action {
    type: typeof LISTEN_TO_USER_RECEIVED_ERROR;
    userId: string;
    error: Error;
}

export type UsersReducerActionTypes =
    | ListenToUserStartedAction
    | ListenToUserReceivedDataAction
    | ListenToUserReceivedErrorAction
    | SignOutAction;
