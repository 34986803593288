import cn from 'classnames';
import * as React from 'react';
import { FC, Suspense } from 'react';
import { Toaster } from 'react-hot-toast';
import { Provider as StoreProvider, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { configureStore } from '../../../store/configureStore';
import { isPersistentDrawerOpenedSelector } from '../../../store/miscellaneous/miscellaneousReducerSelectors';
import { ThemeProvider } from '../../../utils/theme/theme';
import { Routes } from '../../routes/Routes/Routes';
import { CenterContentContainer } from '../CenterContentContainer/CenterContentContainer';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';
import { Loading } from '../Loading/Loading';
import classes from './Application.module.scss';

export const store = configureStore();

const Content = () => {
    const isPersistentDrawerOpened = useSelector(isPersistentDrawerOpenedSelector);
    return (
        <Router>
            <div id="drawers-root" />
            <div className={cn(classes.root, isPersistentDrawerOpened && classes.withPersistentDrawerOpenedRoot)}>
                <Header />
                <div className={classes.content}>
                    <Suspense
                        fallback={
                            <CenterContentContainer
                                fullHeight
                                minFullScreenHeight
                                classes={{ root: classes.centerContentContainer }}
                            >
                                <Loading />
                            </CenterContentContainer>
                        }
                    >
                        <Routes />
                    </Suspense>
                </div>
                <Footer />
            </div>
        </Router>
    );
};

export const Application: FC = () => (
    <Suspense
        fallback={
            <CenterContentContainer fullHeight>
                <Loading />
            </CenterContentContainer>
        }
    >
        <ThemeProvider>
            <StoreProvider {...{ store }}>
                <Content />
            </StoreProvider>
        </ThemeProvider>
        <Toaster position="bottom-left" toastOptions={{ className: classes.toaster }} />
    </Suspense>
);
