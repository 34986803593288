import { getFirestore, doc, updateDoc } from 'firebase/firestore';

import { User } from '../../../types/firestore/users/users.types';
import { firebaseApp } from '../../../utils/firebase/firebase';

const firestore = getFirestore(firebaseApp);

export const updateRemoteLanguage = async (uid: string, language: string) => {
    if (!language) {
        throw new Error('Please pass a valid language.');
    }
    return updateDoc(doc(firestore, `/users/${uid}`), {
        language,
    } as Pick<User, 'language'>);
};
