import * as React from 'react';
import { useContext } from 'react';

import { HeaderContext } from '../../Header.context';
import { HeaderNestedActionsProps } from './HeaderNestedActions.types';
import { InDrawerNestedActions } from './InDrawerNestedActions/InDrawerNestedActions';
import { WithPopperPaperNestedActions } from './WithPopperPaperNestedActions/WithPopperPaperNestedActions';

export const HeaderNestedActions = ({ icon, label, children }: HeaderNestedActionsProps) => {
    const { displayActionsInDrawer } = useContext(HeaderContext);
    if (displayActionsInDrawer) {
        return <InDrawerNestedActions {...{ icon, label }}>{children}</InDrawerNestedActions>;
    }
    return <WithPopperPaperNestedActions {...{ icon }}>{children}</WithPopperPaperNestedActions>;
};
