import { FC, SVGProps } from 'react';

import { ClassNames } from '../../../../utils/styles/styles.types';

export interface SocialProvidersButtonsProps {
    onClose: () => void;
    classes?: ClassNames<'root'>;
}

export enum SocialProviders {
    Facebook = 'facebook',
    Google = 'google',
    Twitter = 'twitter',
}

export interface SocialProviderButtonData {
    icon: FC<SVGProps<any>>;
    name: string;
    color: string;
    authProvider?: any;
}

export type SocialProvidersButtonsData = {
    [provider in SocialProviders]: SocialProviderButtonData;
};
