import { createStore, applyMiddleware, Store, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';

import { combinedReducers } from './combinedReducers';
import { RootState } from './store.types';

export const configureStore = (): Store<RootState> => {
    const middlewares = [thunkMiddleware];

    if (process.env.NODE_ENV === 'development') {
        const composeEnhancers =
            typeof window === 'object' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
                ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
                : compose;

        const enhancer = composeEnhancers(applyMiddleware(...middlewares));
        return createStore(combinedReducers, enhancer);
    }

    return createStore(combinedReducers, applyMiddleware(...middlewares));
};
