import { Reducer } from 'redux';

import { SIGN_OUT } from '../miscellaneous/miscellaneousActions/miscellaneousActions.types';
import {
    LISTEN_TO_ALL_EVENTS_BY_ASSOCIATION_ID_RECEIVED_DATA,
    LISTEN_TO_ALL_EVENTS_BY_ASSOCIATION_ID_RECEIVED_ERROR,
    LISTEN_TO_ALL_EVENTS_BY_ASSOCIATION_ID_STARTED,
    EventsReducerActionTypes,
    LISTEN_TO_EVENT_RECEIVED_DATA,
    LISTEN_TO_EVENT_RECEIVED_ERROR,
    LISTEN_TO_EVENT_STARTED,
} from './eventsActions/eventsActions.types';
import { EventsReducerState } from './eventsReducer.types';
import { EVENTS_REDUCER_INITIAL_STATE } from './eventsReducerInitialState';

export const eventsReducer: Reducer<EventsReducerState, EventsReducerActionTypes> = (
    state = EVENTS_REDUCER_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case LISTEN_TO_EVENT_STARTED:
            return {
                ...state,
                hasReceivedEvents: {
                    ...state.hasReceivedEvents,
                    [action.eventId]: null,
                },
            };

        case LISTEN_TO_EVENT_RECEIVED_DATA:
            return {
                ...state,
                hasReceivedEvents: {
                    ...state.hasReceivedEvents,
                    [action.eventId]: true,
                },
                events: {
                    ...state.events,
                    [action.eventId]: action.data,
                },
            };

        case LISTEN_TO_EVENT_RECEIVED_ERROR:
            return {
                ...state,
                hasReceivedEvents: {
                    ...state.hasReceivedEvents,
                    [action.eventId]: action.error,
                },
            };

        case LISTEN_TO_ALL_EVENTS_BY_ASSOCIATION_ID_STARTED:
            return {
                ...state,
                hasReceivedAllEventsByAssociationId: {
                    ...state.hasReceivedAllEventsByAssociationId,
                    [action.associationId]: null,
                },
            };

        case LISTEN_TO_ALL_EVENTS_BY_ASSOCIATION_ID_RECEIVED_DATA:
            return {
                ...state,
                hasReceivedAllEventsByAssociationId: {
                    ...state.hasReceivedAllEventsByAssociationId,
                    [action.associationId]: true,
                },
                hasReceivedEvents: {
                    ...state.hasReceivedEvents,
                    ...Object.keys(action.data).reduce(
                        (acc, eventId) => ({
                            ...acc,
                            [eventId]: true,
                        }),
                        {} as EventsReducerState['hasReceivedEvents']
                    ),
                },
                events: {
                    ...state.events,
                    ...action.data,
                },
            };

        case LISTEN_TO_ALL_EVENTS_BY_ASSOCIATION_ID_RECEIVED_ERROR:
            return {
                ...state,
                hasReceivedAllEventsByAssociationId: {
                    ...state.hasReceivedAllEventsByAssociationId,
                    [action.associationId]: action.error,
                },
            };

        case SIGN_OUT:
            return EVENTS_REDUCER_INITIAL_STATE;

        default:
            return state;
    }
};
