import { User } from 'firebase/auth';
import { Action } from 'redux';

export const AUTH_STATE_CHANGED_RECEIVED_USER = 'AUTH_STATE_CHANGED_RECEIVED_USER';
export const AUTH_STATE_CHANGED_NO_USER = 'AUTH_STATE_CHANGED_NO_USER';

export const SIGN_OUT = 'SIGN_OUT';

export const SET_IS_HEADER_TRANSPARENT = 'SET_IS_HEADER_TRANSPARENT';

export const SET_IS_PERSISTENT_DRAWER_OPENED = 'SET_IS_PERSISTENT_DRAWER_OPENED';

export interface AuthStateChangedReceivedUserAction extends Action {
    type: typeof AUTH_STATE_CHANGED_RECEIVED_USER;
    firebaseUser: User;
}

export interface AuthStateChangedNoUserAction extends Action {
    type: typeof AUTH_STATE_CHANGED_NO_USER;
}

export interface SignOutAction extends Action {
    type: typeof SIGN_OUT;
}

export interface SetIsHeaderTransparentAction extends Action {
    type: typeof SET_IS_HEADER_TRANSPARENT;
    isHeaderTransparent: boolean;
}

export interface SetIsPersistentDrawerOpenedAction extends Action {
    type: typeof SET_IS_PERSISTENT_DRAWER_OPENED;
    isPersistentDrawerOpened: boolean;
}

export type MiscellaneousReducerActionTypes =
    | AuthStateChangedReceivedUserAction
    | AuthStateChangedNoUserAction
    | SignOutAction
    | SetIsHeaderTransparentAction
    | SetIsPersistentDrawerOpenedAction;
