import { useSpring, animated } from '@react-spring/web';
import React from 'react';

import { useCallbackOpen } from '../../../hooks/useCallbackOpen';
import { CommonColors } from '../../../types/theme/theme.types';
import classes from './Footer.module.scss';
import { SocialNetworkIconProps } from './SocialNetworkIcon.types';

const SocialNetworkIcon = ({ url, icon: Icon }: Pick<SocialNetworkIconProps, 'url' | 'icon'>) => {
    const [isHovering, setIsHovering, setIsNotHovering] = useCallbackOpen();
    const springProps = useSpring({
        iconColor: isHovering ? CommonColors.White : 'rgba(255, 255, 255, 0.6)',
    });
    return (
        <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.socialNetworkLink}
            onMouseEnter={setIsHovering}
            onFocus={setIsHovering}
            onMouseLeave={setIsNotHovering}
            onBlur={setIsNotHovering}
        >
            <animated.span style={{ color: springProps.iconColor, fill: springProps.iconColor }}>
                <Icon className={classes.socialNetworkIcon} />
            </animated.span>
        </a>
    );
};

export default SocialNetworkIcon;
