import cn from 'classnames';
import * as React from 'react';

import { useTheme } from '../../../utils/theme/theme';
import { Typography } from '../Typography/Typography';
import classes from './DialogTitle.module.scss';
import { DialogTitleProps } from './DialogTitle.types';

export const DialogTitle = ({ classes: receivedClasses = {}, children }: DialogTitleProps) => {
    const theme = useTheme();
    return (
        <Typography
            classes={{ root: cn(classes.root, receivedClasses?.root) }}
            variant="h4"
            component="h4"
            style={{ backgroundColor: theme.palette.background.default }}
        >
            {children}
        </Typography>
    );
};
