import { Timestamp } from 'firebase/firestore';

export enum RolesEnum {
    RegularUser = 'regularUser',
    AssociationUser = 'associationUser',
    Admin = 'admin',
}

export interface RegularUserSpecificData {
    birthDate: Timestamp;
    pictureUrl?: string | null;
}

export interface AssociationUserSpecificData {
    associationId: string;
}

export type User = {
    isWaiting: boolean;
    role: RolesEnum;
    firsName: string;
    firstName: string;
    lastName: string;
    email: string;
    language?: string;
    updatedAt: Timestamp;
    createdAt: Timestamp;
    socialmedia: string;
    phoneNumber: string;
    superAssociation: boolean;
} & (RegularUserSpecificData | AssociationUserSpecificData);

export type Users = {
    [userId: string]: User;
};
