import cn from 'classnames';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as FacebookIcon } from '../../../assets/svg/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/svg/instagram.svg';
import { ReactComponent as LindekinIcon } from '../../../assets/svg/linkedin.svg';
import { ReactComponent as TwitterIcon } from '../../../assets/svg/twitter.svg';
import { CommonColors } from '../../../types/theme/theme.types';
import { List } from '../../designSystem/List/List';
import { Typography } from '../../designSystem/Typography/Typography';
import { LanguageSelector } from '../LanguageSelector/LanguageSelector';
import { Logo } from '../Logo/Logo';
import { FOOTER_LINKS_DATA } from './Footer.data';
import classes from './Footer.module.scss';
import FooterLink from './FooterLink';
import SocialNetworkIcon from './SocialNetworkIcon';

export const Footer = () => {
    const { t, ready } = useTranslation('footer');
    const linksEntries = useMemo(() => Object.entries(FOOTER_LINKS_DATA), []);

    if (!ready) {
        return null;
    }

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <div className={cn(classes.column, classes.logoColumnContainer)}>
                    <Logo classes={{ root: classes.logo }} withoutText />
                    <Typography
                        classes={{ root: classes.logoText }}
                        variant="body2"
                        color={CommonColors.White}
                        component="div"
                    >
                        {t('subLogo')}
                    </Typography>
                    <div className={classes.socialNetworkIconContainer}>
                        {[
                            { icon: FacebookIcon, url: 'https://fb.me/jsocial.io' },
                            { icon: InstagramIcon, url: 'https://www.instagram.com/jsocial.io/' },
                            { icon: TwitterIcon, url: 'https://www.twitter.com/jsocialapp' },
                            { icon: LindekinIcon, url: 'https://www.linkedin.com/company/j-social' },
                        ].map(({ icon, url }, index) => (
                            <SocialNetworkIcon url={url} icon={icon} key={index} />
                        ))}
                    </div>
                </div>
                <div className={classes.containerLinks}>
                    <div className={classes.column}>
                        <Typography
                            classes={{ root: classes.title }}
                            variant="h6"
                            component="h6"
                            color={CommonColors.White}
                        >
                            {t('moreInfos')}
                        </Typography>
                        <List classes={{ root: classes.list }}>
                            {linksEntries.map(([linkId, { translationKey, openInNewTab, linkTo }]) => (
                                <FooterLink
                                    key={`link_${linkId}`}
                                    translationKey={translationKey}
                                    openInNewTab={openInNewTab}
                                    linkTo={linkTo}
                                />
                            ))}
                            <li className={classes.languageSelectorListItem}>
                                <LanguageSelector />
                            </li>
                        </List>
                    </div>
                    {/* <div className={classes.column}>
                        <Typography
                            classes={{ root: classes.title }}
                            variant="h6"
                            component="h6"
                            color={CommonColors.White}
                        >
                            {t('contactUs')}
                        </Typography>
                        <List classes={{ root: classes.list }}>
                            <li>
                                <Typography color={CommonColors.White}>{'J-Social HUB'}</Typography>
                            </li>
                            <li>
                                <Typography color={CommonColors.White} classes={{ root: classes.email }}>
                                    <a href="mailto:contat@jsocial.club">{'contact@jsocial.club'}</a>
                                </Typography>
                            </li>
                            <li className={classes.languageSelectorListItem}>
                                <LanguageSelector />
                            </li>
                        </List>
                    </div> */}
                </div>
            </div>
            <div className={classes.algolia}>
                <a href="https://algolia.com/" target="_blank" rel="noopener noreferrer">
                    <img
                        className={classes.searchByAlgolia}
                        src="/assets/svg/search-by-algolia.svg"
                        alt="Search powered by Algolia"
                    />
                </a>
            </div>
        </div>
    );
};
