import { LinksData, Links } from './Footer.types';

export const FOOTER_LINKS_DATA = Object.freeze<LinksData>({
    [Links.Donate]: {
        linkTo: 'https://www.helloasso.com/associations/j-social/formulaires/1',
        openInNewTab: true,
        translationKey: 'links.donate',
    },
    [Links.Faq]: {
        linkTo: '/faq',
        translationKey: 'links.faq',
    },
    [Links.Contact]: {
        linkTo: 'mailto:hello.jsocial@gmail.com',
        openInNewTab: true,
        translationKey: 'links.contact',
    },
});
