import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Dispatch } from 'redux';

import { firebaseApp } from '../../../utils/firebase/firebase';
import { LISTENERS_BUCKET, resetListenersBucket } from '../../listenersBucket';
import {
    AUTH_STATE_CHANGED_NO_USER,
    AUTH_STATE_CHANGED_RECEIVED_USER,
    AuthStateChangedNoUserAction,
    AuthStateChangedReceivedUserAction,
    SET_IS_PERSISTENT_DRAWER_OPENED,
    SET_IS_HEADER_TRANSPARENT,
    SetIsPersistentDrawerOpenedAction,
    SetIsHeaderTransparentAction,
    SIGN_OUT,
    SignOutAction,
} from './miscellaneousActions.types';

const auth = getAuth(firebaseApp);

export const listenForAuthStateChange =
    () => (dispatch: Dispatch<AuthStateChangedReceivedUserAction | AuthStateChangedNoUserAction>) => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                console.log('[Users Actions][listenForAuthStateChange] Received uid.');
                dispatch({
                    type: AUTH_STATE_CHANGED_RECEIVED_USER,
                    firebaseUser: user,
                });
                await user.getIdToken(true);
                return null;
            }
            console.log('[Users Actions][listenForAuthStateChange] Did not receive users.');
            return dispatch({
                type: AUTH_STATE_CHANGED_NO_USER,
            });
        });
    };

export const signOut = () => (dispatch: Dispatch<SignOutAction>) => {
    console.log('[User Actions][signOut] Unsubscribing from all listeners and signing out...');
    Object.entries(LISTENERS_BUCKET).forEach(([key, unsubscribe]) => {
        console.log('[signOut] Unsubscribing from listener: ', key);
        unsubscribe();
    });
    resetListenersBucket();
    return auth.signOut().then(() =>
        dispatch({
            type: SIGN_OUT,
        })
    );
};

export const setIsHeaderTransparent =
    (isHeaderTransparent: boolean) => (dispatch: Dispatch<SetIsHeaderTransparentAction>) => {
        console.log('[User Actions][setIsHeaderTransparent] Setting isHeaderTransparent...');
        return dispatch({
            type: SET_IS_HEADER_TRANSPARENT,
            isHeaderTransparent,
        });
    };

export const setIsPersistentDrawerOpened =
    (isPersistentDrawerOpened: boolean) => (dispatch: Dispatch<SetIsPersistentDrawerOpenedAction>) => {
        console.log('[User Actions][setIsPersistentDrawerOpened] Setting isPersistentDrawerOpened...');
        return dispatch({
            type: SET_IS_PERSISTENT_DRAWER_OPENED,
            isPersistentDrawerOpened,
        });
    };
