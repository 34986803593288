import { HTMLProps, ReactNode } from 'react';

import { ClassNames } from '../../../utils/styles/styles.types';

export enum TextFieldVariants {
    Outlined = 'outlined',
    Flat = 'flat',
}

export interface TextFieldProps extends Omit<HTMLProps<'input' | 'textarea'>, 'ref'> {
    variant?: TextFieldVariants;
    fullWidth?: boolean;
    multiline?: boolean;
    disabled?: boolean;
    rootProps?: HTMLProps<HTMLDivElement>;
    beforeInput?: ReactNode;
    children?: ReactNode;
    classes?: ClassNames<'root' | 'input'>;
}
