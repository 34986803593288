import { Action } from 'redux';

import { Categories, Category } from '../../../types/firestore/categories/categories.types';
import { SignOutAction } from '../../miscellaneous/miscellaneousActions/miscellaneousActions.types';

export const LISTEN_TO_CATEGORY_STARTED = 'LISTEN_TO_CATEGORY_STARTED';
export const LISTEN_TO_CATEGORY_RECEIVED_DATA = 'LISTEN_TO_CATEGORY_RECEIVED_DATA';
export const LISTEN_TO_CATEGORY_RECEIVED_ERROR = 'LISTEN_TO_CATEGORY_RECEIVED_ERROR';

export const LISTEN_TO_ALL_CATEGORIES_STARTED = 'LISTEN_TO_ALL_CATEGORIES_STARTED';
export const LISTEN_TO_ALL_CATEGORIES_RECEIVED_DATA = 'LISTEN_TO_ALL_CATEGORIES_RECEIVED_DATA';
export const LISTEN_TO_ALL_CATEGORIES_RECEIVED_ERROR = 'LISTEN_TO_ALL_CATEGORIES_RECEIVED_ERROR';

export interface ListenToCategoryStartedAction extends Action {
    type: typeof LISTEN_TO_CATEGORY_STARTED;
    categoryId: string;
}

export interface ListenToCategoryReceivedDataAction extends Action {
    type: typeof LISTEN_TO_CATEGORY_RECEIVED_DATA;
    categoryId: string;
    data: Category;
}

export interface ListenToCategoryReceivedErrorAction extends Action {
    type: typeof LISTEN_TO_CATEGORY_RECEIVED_ERROR;
    categoryId: string;
    error: Error;
}

export interface ListenToAllCategoriesStartedAction extends Action {
    type: typeof LISTEN_TO_ALL_CATEGORIES_STARTED;
}

export interface ListenToAllCategoriesReceivedDataAction extends Action {
    type: typeof LISTEN_TO_ALL_CATEGORIES_RECEIVED_DATA;
    data: Categories;
}

export interface ListenToAllCategoriesReceivedErrorAction extends Action {
    type: typeof LISTEN_TO_ALL_CATEGORIES_RECEIVED_ERROR;
    error: Error;
}

export type CategoriesReducerActionTypes =
    | ListenToCategoryStartedAction
    | ListenToCategoryReceivedDataAction
    | ListenToCategoryReceivedErrorAction
    | ListenToAllCategoriesStartedAction
    | ListenToAllCategoriesReceivedDataAction
    | ListenToAllCategoriesReceivedErrorAction
    | SignOutAction;
