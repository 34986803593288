import cn from 'classnames';
import * as React from 'react';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

import {
    isHeaderTransparentSelector,
    isPersistentDrawerOpenedSelector,
} from '../../../store/miscellaneous/miscellaneousReducerSelectors';
import { DARK_THEME } from '../../../utils/theme/darkTheme';
import { ThemeProvider } from '../../../utils/theme/theme';
import { Paper } from '../../designSystem/Paper/Paper';
import { Logo } from '../Logo/Logo';
import { Sponsor } from '../Sponsor/Sponsor';
import { HeaderContext } from './Header.context';
import classes from './Header.module.scss';
import { HeaderActions } from './HeaderActions/HeaderActions';

export const Header = () => {
    const { t } = useTranslation('header');
    const isTransparent = useSelector(isHeaderTransparentSelector);
    const isPersistentDrawerOpened = useSelector(isPersistentDrawerOpenedSelector);
    const actionsReference = useRef<HTMLDivElement>(null);

    const displayActionsInDrawer = useMediaQuery({
        query: '(max-width: 500px)',
    });

    const contextValue = useMemo(
        () => ({
            displayActionsInDrawer,
        }),
        [displayActionsInDrawer]
    );
    return (
        <ThemeProvider theme={DARK_THEME}>
            <Paper
                classes={{ root: cn(classes.root, Boolean(isTransparent) && classes.transparent) }}
                style={!isTransparent ? { backgroundColor: 'rgb(128 134 210)' } : {}}
            >
                {!isPersistentDrawerOpened && (
                    <>
                        <Link to="/">
                            <Logo />
                        </Link>
                        <Sponsor t={t} />
                    </>
                )}

                <div ref={actionsReference} className={classes.actions}>
                    <HeaderContext.Provider value={contextValue}>
                        <HeaderActions />
                    </HeaderContext.Provider>
                </div>
            </Paper>
        </ThemeProvider>
    );
};
