import * as React from 'react';
import { lazy } from 'react';
import { Route } from 'react-router-dom';

export const SIGNED_IN_ROUTES = [
    <Route
        key="[SignedInRoutes][Dashboard]"
        path="/dashboard"
        component={lazy(() => import('../routesComponents/Dashboard/Dashboard'))}
    />,
    <Route
        key="[SignedInRoutes][EventPage]"
        path="/event/:eventId"
        component={lazy(() => import('../routesComponents/EventPage/EventPage'))}
    />,
    <Route
        key="[SignedInRoutes][Search]"
        path="/search"
        component={lazy(() => import('../routesComponents/Search/Search'))}
    />,
    <Route
        key="[SignedInRoutes][AssociationPage]"
        path="/association/:associationId"
        component={lazy(() => import('../routesComponents/Search/Search'))}
    />,
];
