import cn from 'classnames';
import * as React from 'react';

import classes from './CenterContentContainer.module.scss';
import { CenterContentContainerProps } from './CenterContentContainer.type';

export const CenterContentContainer = ({
    fullHeight,
    minFullScreenHeight,
    children,
    classes: receivedClasses = {},
}: CenterContentContainerProps) => (
    <div
        className={cn(
            classes.root,
            fullHeight && classes.fullHeight,
            minFullScreenHeight && classes.minFullScreenHeight,
            receivedClasses.root
        )}
    >
        {children}
    </div>
);
