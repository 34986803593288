import cn from 'classnames';
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { PaletteNames } from '../../../../types/theme/theme.types';
import { useTheme } from '../../../../utils/theme/theme';
import { Divider } from '../../../designSystem/Divider/Divider';
import { Typography } from '../../../designSystem/Typography/Typography';
import { TypographySpecificColors } from '../../../designSystem/Typography/Typography.types';
import { AuthenticationTypes } from '../AuthenticateDialog.types';
import { DISPLAYED_TYPES } from './TypeToggle.data';
import classes from './TypeToggle.module.scss';
import { TypeToggleProps } from './TypeToggle.types';

export const TypeToggle = ({ currentType, setCurrentType, classes: receivedClasses = {} }: TypeToggleProps) => {
    const { t, ready } = useTranslation('authenticateDialog');
    const theme = useTheme();

    const handleTypeSelection = useCallback(
        (type: AuthenticationTypes) => () => {
            setCurrentType(type);
        },
        [setCurrentType]
    );

    if (!ready) {
        return null;
    }

    return (
        <div className={cn(classes.root, receivedClasses.root)}>
            {DISPLAYED_TYPES.map((type) => {
                const isSelected = currentType === type;
                return (
                    <button
                        className={classes.button}
                        key={`type_${type}`}
                        type="button"
                        onClick={handleTypeSelection(type)}
                    >
                        <Typography
                            classes={{ root: classes.typography }}
                            color={isSelected ? PaletteNames.Primary : TypographySpecificColors.TextSecondary}
                        >
                            {t(`typeToggle.${type}`)}
                        </Typography>
                        {isSelected && (
                            <div
                                className={classes.underline}
                                style={{
                                    backgroundColor: theme.palette.primary.main,
                                }}
                            />
                        )}
                    </button>
                );
            })}
            <Divider />
        </div>
    );
};
