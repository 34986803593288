import cn from 'classnames';
import * as React from 'react';

import { CloseButton } from '../../commons/CloseButton/CloseButton';
import classes from './DrawerCloseIcon.module.scss';
import { DrawerCloseIconProps } from './DrawerCloseIcon.types';

export const DrawerCloseIcon = ({ onClose, classes: receivedClasses = {} }: DrawerCloseIconProps) => (
    <CloseButton classes={{ root: cn(classes.root, receivedClasses.root) }} {...{ onClose }} />
);
