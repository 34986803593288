import { ReactNode } from 'react';

import { ClassNames } from '../../../utils/styles/styles.types';

export enum DrawerVariants {
    Temporary = 'temporary',
    Persistent = 'persistent',
}

export enum DrawerPlacements {
    Left = 'left',
    Right = 'right',
}

export type DrawerProps = {
    placement?: DrawerPlacements;
    variant?: DrawerVariants;
    open?: boolean;
    onClose?: () => void;
    classes?: ClassNames<'root'>;
    children: ReactNode;
};
