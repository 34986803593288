import { useSpring, animated } from '@react-spring/web';
import cn from 'classnames';
import { forwardRef, useMemo } from 'react';
import * as React from 'react';

import { useCallbackOpen } from '../../../hooks/useCallbackOpen';
import { getValueFromColorName } from '../../../utils/styles/getValueFromColorName';
import { useTheme } from '../../../utils/theme/theme';
import classes from './Typography.module.scss';
import { TypographyProps, TypographySpecificColors } from './Typography.types';

export const Typography = forwardRef(
    (
        {
            color = TypographySpecificColors.TextPrimary,
            component: Component = animated.span,
            variant = 'body1',
            italic = false,
            uppercase = false,
            classes: receivedClasses = {},
            style: receivedStyle,
            children,
            changeColorOnHover,
            ...other
        }: TypographyProps,
        ref
    ) => {
        const theme = useTheme();
        const colorValue = useMemo(() => getValueFromColorName({ color, theme }), [color, theme]);
        const [isHovering, setIsHovering, setIsNotHovering] = useCallbackOpen();
        const springProps = useSpring({
            textColor:
                isHovering && changeColorOnHover
                    ? getValueFromColorName({ color: changeColorOnHover, theme })
                    : colorValue,
        });

        return (
            <Component
                className={cn(
                    classes.root,
                    classes[variant],
                    italic && classes.italic,
                    uppercase && classes.uppercase,
                    receivedClasses.root
                )}
                {...{ ref }}
                {...other}
                {...(changeColorOnHover && {
                    onMouseEnter: setIsHovering,
                    onFocus: setIsHovering,
                    onMouseLeave: setIsNotHovering,
                    onBlur: setIsNotHovering,
                })}
                style={{
                    color: changeColorOnHover ? springProps.textColor : colorValue,
                    ...receivedStyle,
                }}
            >
                {children}
            </Component>
        );
    }
);
