import { animated, useTransition } from '@react-spring/web';
import cn from 'classnames';
import * as React from 'react';
import { useCallback, useEffect, useRef } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { Portal } from 'react-portal';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { setIsPersistentDrawerOpened } from '../../../store/miscellaneous/miscellaneousActions/miscellaneousActions';
import { useTheme } from '../../../utils/theme/theme';
import { DrawerCloseIcon } from '../DrawerCloseIcon/DrawerCloseIcon';
import { Paper } from '../Paper/Paper';
import classes from './Drawer.module.scss';
import { getDrawerTransitionsSpringProps } from './Drawer.springProps';
import { DrawerPlacements, DrawerProps, DrawerVariants } from './Drawer.types';

const getRootElement = () => document.getElementById('drawers-root');

const PersistentDrawer = ({ children, classes: receivedClasses = {} }: DrawerProps) => {
    const { palette } = useTheme();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setIsPersistentDrawerOpened(true));
        return () => {
            dispatch(setIsPersistentDrawerOpened(false));
        };
    }, [dispatch]);

    return (
        <Portal node={getRootElement()}>
            <Paper
                component={animated.div}
                classes={{
                    root: cn(classes.root, receivedClasses.root),
                }}
                style={{ backgroundColor: palette.background.default }}
            >
                {children}
            </Paper>
        </Portal>
    );
};

const TemporaryDrawer = ({
    variant,
    placement = DrawerPlacements.Left,
    open,
    onClose,
    children,
    classes: receivedClasses = {},
}: DrawerProps) => {
    const { palette } = useTheme();
    const location = useLocation();
    const oldPathName = useRef(location.pathname);

    const isOpened = Boolean(variant === DrawerVariants.Persistent || open);

    const transitions = useTransition(isOpened, {
        ...getDrawerTransitionsSpringProps(placement),
        immediate: variant === DrawerVariants.Persistent,
    });

    useEffect(() => {
        if (open) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'auto';
        }
        return () => {
            document.body.style.overflowY = 'auto';
        };
    }, [open]);

    const handleClose = useCallback(() => {
        if (typeof onClose !== 'function') {
            return;
        }
        onClose();
    }, [onClose]);

    useEffect(() => {
        if (location.pathname !== oldPathName.current) {
            handleClose();
        }
        oldPathName.current = location.pathname;
    }, [handleClose, oldPathName, location]);

    return (
        <Portal node={getRootElement()}>
            {transitions(
                (props, item) =>
                    item && (
                        <ClickAwayListener onClickAway={handleClose}>
                            <Paper
                                component={animated.div}
                                classes={{
                                    root: cn(classes.root, classes.temporary, classes[placement], receivedClasses.root),
                                }}
                                style={{ backgroundColor: palette.background.default, ...props } as any}
                            >
                                <DrawerCloseIcon onClose={handleClose} />
                                {children}
                            </Paper>
                        </ClickAwayListener>
                    )
            )}
        </Portal>
    );
};

export const Drawer = ({ children, ...props }: DrawerProps) => {
    const { variant } = props;
    if (variant === DrawerVariants.Temporary) {
        return <TemporaryDrawer {...props}>{children}</TemporaryDrawer>;
    }
    return <PersistentDrawer {...props}>{children}</PersistentDrawer>;
};
