import { collection, doc, getFirestore } from 'firebase/firestore';
import { Dispatch } from 'redux';

import { listenToCollectionAndDispatchActions } from '../../../utils/actions/listenToCollectionAndDispatchActions';
import { listenToDocumentAndDispatchActions } from '../../../utils/actions/listenToDocumentAndDispatchActions';
import { firebaseApp } from '../../../utils/firebase/firebase';
import {
    LISTEN_TO_ALL_WAITING_USERS_RECEIVED_DATA,
    LISTEN_TO_ALL_WAITING_USERS_RECEIVED_ERROR,
    LISTEN_TO_ALL_WAITING_USERS_STARTED,
    LISTEN_TO_WAITING_USER_RECEIVED_DATA,
    LISTEN_TO_WAITING_USER_RECEIVED_ERROR,
    LISTEN_TO_WAITING_USER_STARTED,
    ListenToAllWaitingUserReceivedErrorAction,
    ListenToAllWaitingUsersReceivedDataAction,
    ListenToAllWaitingUsersStartedAction,
    ListenToWaitingUserReceivedDataAction,
    ListenToWaitingUserReceivedErrorAction,
    ListenToWaitingUserStartedAction,
} from './waitingUsersActions.types';

const firestore = getFirestore(firebaseApp);

export const listenToWaitingUser = (userId: string) => (dispatch: Dispatch) =>
    listenToDocumentAndDispatchActions<
        | ListenToWaitingUserStartedAction
        | ListenToWaitingUserReceivedDataAction
        | ListenToWaitingUserReceivedErrorAction
    >({
        dispatch,
        document: doc(firestore, `/waitingUsers/${userId}`),
        startedEventType: LISTEN_TO_WAITING_USER_STARTED,
        receivedDataEventType: LISTEN_TO_WAITING_USER_RECEIVED_DATA,
        receivedErrorEventType: LISTEN_TO_WAITING_USER_RECEIVED_ERROR,
        startedListeningLog: `[WaitingUsers Actions][listenToWaitingUser] Listening to waiting user : ${userId}...`,
        receivedDataLog: '[WaitingUsersTypes Actions][listenToWaitingUser] Received waiting user.',
        receivedErrorLog: '[WaitingUsersTypes Actions][listenToWaitingUser] Received error.',
        additionalDispatchValues: {
            userId,
        },
    });

export const listenToAllWaitingUsers = () => (dispatch: Dispatch) =>
    listenToCollectionAndDispatchActions<
        | ListenToAllWaitingUsersStartedAction
        | ListenToAllWaitingUsersReceivedDataAction
        | ListenToAllWaitingUserReceivedErrorAction
    >({
        dispatch,
        collection: collection(firestore, '/waitingUsers'),
        startedEventType: LISTEN_TO_ALL_WAITING_USERS_STARTED,
        receivedDataEventType: LISTEN_TO_ALL_WAITING_USERS_RECEIVED_DATA,
        receivedErrorEventType: LISTEN_TO_ALL_WAITING_USERS_RECEIVED_ERROR,
        startedListeningLog: `[WaitingUsers Actions][listenToAllWaitingUsers] Listening to all waiting users...`,
        receivedDataLog: '[WaitingUsersTypes Actions][listenToAllWaitingUsers] Received waiting users.',
        receivedErrorLog: '[WaitingUsersTypes Actions][listenToAllWaitingUsers] Received error.',
    });
