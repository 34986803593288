import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useCurrentUser } from '../../../../../../hooks/fetchers/users/useCurrentUser';
import { RolesEnum } from '../../../../../../types/firestore/users/users.types';
import { Button } from '../../../../../designSystem/Button/Button';
import { ButtonVariants } from '../../../../../designSystem/Button/Button.types';

export const RolesButtons = () => {
    const { t, ready } = useTranslation('header');

    const { user } = useCurrentUser();
    const { role } = user || {};

    if (!ready) {
        return null;
    }

    if (role === RolesEnum.AssociationUser) {
        return (
            <Link to="/dashboard/create-event">
                <Button variant={ButtonVariants.Outlined}>
                    {t('actions.signedIn.roleSpecifics.associationUser.createEvent.label')}
                </Button>
            </Link>
        );
    }

    if (role === RolesEnum.RegularUser) {
        return (
            <Link to="/search">
                <Button variant={ButtonVariants.Outlined}>
                    {t('actions.signedIn.roleSpecifics.regularUser.search.label')}
                </Button>
            </Link>
        );
    }

    if (role === RolesEnum.Admin) {
        return (
            <Link to="/dashboard">
                <Button variant={ButtonVariants.Outlined}>
                    {t('actions.signedIn.roleSpecifics.admin.dashboard.label')}
                </Button>
            </Link>
        );
    }

    return null;
};
