import { animated, useTransition } from '@react-spring/web';
import cn from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { usePopper } from 'react-popper';
import { Portal } from 'react-portal';

import { Paper } from '../Paper/Paper';
import classes from './PopperPaper.module.scss';
import { POPPER_PAPER_TRANSITIONS_SPRING_PROPS } from './PopperPaper.springProps';
import { PopperPaperProps } from './PopperPaper.types';

export const PopperPaper = ({
    anchorElement,
    open,
    handlePointerEvents = true,
    children,
    onClose,
    classes: receivedClasses = {},
}: PopperPaperProps) => {
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();

    const { styles, attributes, forceUpdate } = usePopper(anchorElement, popperElement, {
        placement: 'top',
        modifiers: [
            {
                name: 'computeStyles',
                options: {
                    gpuAcceleration: false,
                },
            },
            {
                name: 'preventOverflow',
                options: {
                    altAxis: true,
                    padding: 8 * 2,
                },
            },
        ],
    });

    useEffect(() => {
        if (typeof forceUpdate !== 'function') {
            return;
        }
        forceUpdate();
    }, [forceUpdate, children]);

    const transitions = useTransition(open, POPPER_PAPER_TRANSITIONS_SPRING_PROPS);

    const handleClickAway = useCallback(() => {
        if (typeof onClose === 'function') {
            onClose();
        }
    }, [onClose]);

    if (!children || !anchorElement) {
        return null;
    }

    return (
        <Portal>
            {transitions(
                (props, item) =>
                    item && (
                        <ClickAwayListener onClickAway={handleClickAway}>
                            <animated.div
                                className={cn(classes.root, receivedClasses.root)}
                                ref={setPopperElement}
                                style={{
                                    ...styles.popper,
                                    ...props,
                                    ...(handlePointerEvents && {
                                        pointerEvents: open ? 'all' : 'none',
                                    }),
                                }}
                                {...attributes.popper}
                            >
                                <Paper
                                    classes={{
                                        root: cn(classes.paper, receivedClasses.paper),
                                    }}
                                >
                                    {children}
                                </Paper>
                            </animated.div>
                        </ClickAwayListener>
                    )
            )}
        </Portal>
    );
};
