import * as React from 'react';

import classes from './Sponsor.module.scss';
import { SponsorProps } from './Sponsor.types';

export const Sponsor = ({ t }: SponsorProps) => (
    <div className={classes.sponsor}>
        <a target="_blank" rel="noreferrer" href="https://www.fsju.org">
            <span>
                <img className={classes.sponsorLogo} src="/assets/png/FSJU.png" alt="Sponsor" />
            </span>
            <span className={classes.sponsorName}>
                <i>{t('sponsor')}</i>
            </span>
        </a>
    </div>
);
