import * as React from 'react';
import { lazy } from 'react';
import { Route } from 'react-router-dom';

export const COMMONS_ROUTES = [
    <Route key="[CommonRoutes][FAQ]" exact path="/faq" component={lazy(() => import('../routesComponents/FAQ/FAQ'))} />,
    <Route
        key="[CommonRoutes][PARTNERS]"
        exact
        path="/partners"
        component={lazy(() => import('../routesComponents/Partners/Partners'))}
    />,
    <Route
        key="[CommonRoutes][Landing]"
        path="/"
        component={lazy(() => import('../routesComponents/Landing/Landing'))}
    />,
];
