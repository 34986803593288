import { Reducer } from 'redux';

import { SIGN_OUT } from '../miscellaneous/miscellaneousActions/miscellaneousActions.types';
import {
    CategoriesReducerActionTypes,
    LISTEN_TO_ALL_CATEGORIES_RECEIVED_DATA,
    LISTEN_TO_ALL_CATEGORIES_RECEIVED_ERROR,
    LISTEN_TO_ALL_CATEGORIES_STARTED,
    LISTEN_TO_CATEGORY_RECEIVED_DATA,
    LISTEN_TO_CATEGORY_RECEIVED_ERROR,
    LISTEN_TO_CATEGORY_STARTED,
} from './categoriesActions/categoriesActions.types';
import { CategoriesReducerState } from './categoriesReducer.types';
import { CATEGORIES_REDUCER_INITIAL_STATE } from './categoriesReducerInitialState';

export const categoriesReducer: Reducer<CategoriesReducerState, CategoriesReducerActionTypes> = (
    state = CATEGORIES_REDUCER_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case LISTEN_TO_CATEGORY_STARTED:
            return {
                ...state,
                hasReceivedCategories: {
                    ...state.hasReceivedCategories,
                    [action.categoryId]: null,
                },
            };

        case LISTEN_TO_CATEGORY_RECEIVED_DATA:
            return {
                ...state,
                hasReceivedCategories: {
                    ...state.hasReceivedCategories,
                    [action.categoryId]: true,
                },
                categories: {
                    ...state.categories,
                    [action.categoryId]: action.data,
                },
            };

        case LISTEN_TO_CATEGORY_RECEIVED_ERROR:
            return {
                ...state,
                hasReceivedCategories: {
                    ...state.hasReceivedCategories,
                    [action.categoryId]: action.error,
                },
            };

        case LISTEN_TO_ALL_CATEGORIES_STARTED:
            return {
                ...state,
                hasReceivedAllCategories: null,
            };

        case LISTEN_TO_ALL_CATEGORIES_RECEIVED_DATA:
            return {
                ...state,
                hasReceivedAllCategories: true,
                hasReceivedCategories: Object.keys(action.data).reduce(
                    (acc, categoryId) => ({
                        ...acc,
                        [categoryId]: true,
                    }),
                    {} as CategoriesReducerState['hasReceivedCategories']
                ),
                categories: action.data,
            };

        case LISTEN_TO_ALL_CATEGORIES_RECEIVED_ERROR:
            return {
                ...state,
                hasReceivedAllCategories: action.error,
            };

        case SIGN_OUT:
            return CATEGORIES_REDUCER_INITIAL_STATE;

        default:
            return state;
    }
};
