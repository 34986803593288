import cn from 'classnames';
import * as React from 'react';
import { forwardRef } from 'react';

import { useTheme } from '../../../utils/theme/theme';
import classes from './TextField.module.scss';
import { TextFieldProps, TextFieldVariants } from './TextField.types';

export const TextField = forwardRef(
    (
        {
            variant = TextFieldVariants.Outlined,
            fullWidth,
            multiline,
            disabled,
            rootProps,
            classes: receivedClasses = {},
            beforeInput,
            children,
            ...other
        }: TextFieldProps,
        ref
    ) => {
        const theme = useTheme();

        const InputElement = multiline ? 'textarea' : 'input';
        return (
            <div
                className={cn(
                    classes.root,
                    fullWidth && classes.fullWidth,
                    disabled && classes.disabled,
                    multiline && classes.multiline,
                    beforeInput && classes.withBeforeInput,
                    children && classes.withChildren,
                    classes[`variant-${variant}`],
                    receivedClasses?.root
                )}
                style={{
                    color: theme.palette.text.primary,
                    ...(variant === TextFieldVariants.Outlined && {
                        boxShadow: theme.components.textField.outlined.boxShadow,
                    }),
                    ...(variant === TextFieldVariants.Flat && {
                        backgroundColor: theme.components.textField.flat.backgroundColor,
                    }),
                }}
                {...rootProps}
            >
                {Boolean(beforeInput) && <div className={classes.childrenContainer}>{beforeInput}</div>}
                <InputElement {...{ ref }} className={cn(classes.input, receivedClasses?.input)} {...(other as any)} />
                {Boolean(children) && <div className={classes.childrenContainer}>{children}</div>}
            </div>
        );
    }
);
