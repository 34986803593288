import cn from 'classnames';
import * as React from 'react';

import { CloseButton } from '../../commons/CloseButton/CloseButton';
import classes from './DialogCloseIcon.module.scss';
import { DialogCloseIconProps } from './DialogCloseIcon.types';

export const DialogCloseIcon = ({ onClose, classes: receivedClasses = {} }: DialogCloseIconProps) => (
    <CloseButton classes={{ root: cn(classes.root, receivedClasses.root) }} {...{ onClose }} />
);
