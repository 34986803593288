import { yupResolver } from '@hookform/resolvers/yup';
import { FirebaseError } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import * as React from 'react';
import { useCallback, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LockIcon } from '../../../../assets/svg/lock.svg';
import { ReactComponent as PersonIcon } from '../../../../assets/svg/person.svg';
import { useCallbackOpen } from '../../../../hooks/useCallbackOpen';
import { PaletteNames } from '../../../../types/theme/theme.types';
import { firebaseApp } from '../../../../utils/firebase/firebase';
import { Button } from '../../../designSystem/Button/Button';
import { ButtonVariants } from '../../../designSystem/Button/Button.types';
import { TextField } from '../../../designSystem/TextField/TextField';
import { Typography } from '../../../designSystem/Typography/Typography';
import { CenterContentContainer } from '../../CenterContentContainer/CenterContentContainer';
import { FormField } from '../../FormField/FormField';
import { Loading } from '../../Loading/Loading';
import { AuthenticateDialogContext } from '../AuthenticateDialog.context';
import { AuthenticationTypes } from '../AuthenticateDialog.types';
import classes from './SignInForm.module.scss';
import { getSignInSchema } from './SignInForm.schema';
import { SignInFormSchema } from './SignInForm.types';

const auth = getAuth(firebaseApp);

export const SignInForm = () => {
    const { setCurrentType, onClose } = useContext(AuthenticateDialogContext);
    const { t, ready } = useTranslation(['form', 'authenticateDialog', 'firebaseErrorMessages']);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<SignInFormSchema>({
        reValidateMode: 'onSubmit',
        resolver: yupResolver(getSignInSchema(t)),
    });

    const [globalError, setGlobalError] = useState<FirebaseError>();
    const [loading, setIsLoading, setIsNotLoading] = useCallbackOpen(false);

    const onSubmit = handleSubmit(async (values) => {
        setIsLoading();
        try {
            await signInWithEmailAndPassword(auth, values.email, values.password);
            onClose();
        } catch (error) {
            setGlobalError(error as FirebaseError);
        }
        setIsNotLoading();
    });

    const handleForgotPasswordButtonClick = useCallback(() => {
        setCurrentType(AuthenticationTypes.ForgotPassword);
    }, [setCurrentType]);

    if (loading || !ready) {
        return (
            <CenterContentContainer>
                <Loading />
            </CenterContentContainer>
        );
    }

    return (
        <form className={classes.root} {...{ onSubmit }}>
            {Boolean(globalError) && (
                <Typography classes={{ root: classes.globalError }} color={PaletteNames.Error}>
                    {t(`firebaseErrorMessages:${globalError?.code}`)}
                </Typography>
            )}
            <FormField classes={{ root: classes.formField }} error={errors.email}>
                <TextField
                    fullWidth
                    placeholder={t('authenticateDialog:forms.signIn.email.placeholder')}
                    type="email"
                    beforeInput={<PersonIcon className={classes.icon} />}
                    {...register('email')}
                />
            </FormField>
            <FormField classes={{ root: classes.formField }} error={errors.password}>
                <TextField
                    fullWidth
                    placeholder={t('authenticateDialog:forms.signIn.password.placeholder')}
                    type="password"
                    beforeInput={<LockIcon className={classes.icon} />}
                    {...register('password')}
                />
            </FormField>
            <button className={classes.forgotPassword} type="button" onClick={handleForgotPasswordButtonClick}>
                <Typography variant="body2">{t('authenticateDialog:forms.signIn.forgotPassword')}</Typography>
            </button>
            <Button classes={{ root: classes.submit }} variant={ButtonVariants.Contained} color={PaletteNames.Primary}>
                {t('authenticateDialog:forms.signIn.signIn')}
            </Button>
        </form>
    );
};
