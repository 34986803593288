import { AssociationsReducerState, SubAssociationsReducerState } from './associationsReducer.types';

export const ASSOCIATIONS_REDUCER_INITIAL_STATE = Object.freeze<AssociationsReducerState>({
    hasReceivedAssociations: {},
    associations: {},
});

export const SUB_ASSOCIATIONS_REDUCER_INITIAL_STATE = Object.freeze<SubAssociationsReducerState>({
    hasReceivedAssociations: {},
    associations: {},
});
