import * as React from 'react';
import { lazy } from 'react';
import { Redirect, Route } from 'react-router-dom';

export const WAITING_FOR_VERIFICATION_ROUTES = [
    <Route
        key="[WaitingForVerificationRoutes][WaitingForVerification]"
        path="/waiting-for-verification"
        component={lazy(() => import('../routesComponents/WaitingForVerification/WaitingForVerification'))}
    />,
    <Redirect
        key="[WaitingForVerificationRoutes][RedirectToWaitingForVerification]"
        path="*"
        to="/waiting-for-verification"
    />,
];
