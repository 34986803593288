import { Reducer } from 'redux';

import { SIGN_OUT } from '../miscellaneous/miscellaneousActions/miscellaneousActions.types';
import {
    LISTEN_TO_ASSOCIATION_RECEIVED_DATA,
    LISTEN_TO_ASSOCIATION_RECEIVED_ERROR,
    LISTEN_TO_ASSOCIATION_STARTED,
    AssociationsReducerActionTypes,
    SubAssociationsReducerActionTypes,
    LISTEN_TO_SUBASSOCIATIONS_STARTED,
    LISTEN_TO_SUBASSOCIATIONS_RECEIVED_ERROR,
    LISTEN_TO_SUBASSOCIATIONS_RECEIVED_DATA,
} from './associationsActions/associationsActions.types';
import { AssociationsReducerState, SubAssociationsReducerState } from './associationsReducer.types';
import {
    ASSOCIATIONS_REDUCER_INITIAL_STATE,
    SUB_ASSOCIATIONS_REDUCER_INITIAL_STATE,
} from './associationsReducerInitialState';

export const associationsReducer: Reducer<AssociationsReducerState, AssociationsReducerActionTypes> = (
    state = ASSOCIATIONS_REDUCER_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case LISTEN_TO_ASSOCIATION_STARTED:
            return {
                ...state,
                hasReceivedAssociations: {
                    ...state.hasReceivedAssociations,
                    [action.associationId]: null,
                },
            };

        case LISTEN_TO_ASSOCIATION_RECEIVED_DATA:
            return {
                ...state,
                hasReceivedAssociations: {
                    ...state.hasReceivedAssociations,
                    [action.associationId]: true,
                },
                associations: {
                    ...state.associations,
                    [action.associationId]: action.data,
                },
            };

        case LISTEN_TO_ASSOCIATION_RECEIVED_ERROR:
            return {
                ...state,
                hasReceivedAssociations: {
                    ...state.hasReceivedAssociations,
                    [action.associationId]: action.error,
                },
            };

        case SIGN_OUT:
            return ASSOCIATIONS_REDUCER_INITIAL_STATE;

        default:
            return state;
    }
};

export const subAssociationsReducer: Reducer<SubAssociationsReducerState, SubAssociationsReducerActionTypes> = (
    state = SUB_ASSOCIATIONS_REDUCER_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case LISTEN_TO_SUBASSOCIATIONS_STARTED:
            return {
                ...state,
                hasReceivedAssociations: {
                    ...state.hasReceivedAssociations,
                    [action.createdBy]: null,
                },
            };

        case LISTEN_TO_SUBASSOCIATIONS_RECEIVED_DATA:
            return {
                ...state,
                hasReceivedAssociations: {
                    ...state.hasReceivedAssociations,
                    [action.createdBy]: true,
                },
                associations: {
                    ...state.associations,
                    [action.createdBy]: action.data,
                },
            };

        case LISTEN_TO_SUBASSOCIATIONS_RECEIVED_ERROR:
            return {
                ...state,
                hasReceivedAssociations: {
                    ...state.hasReceivedAssociations,
                    [action.createdBy]: action.error,
                },
            };

        case SIGN_OUT:
            return SUB_ASSOCIATIONS_REDUCER_INITIAL_STATE;

        default:
            return state;
    }
};
