import * as React from 'react';
import { WaveLoading } from 'respinner';

import { useTheme } from '../../../utils/theme/theme';
import { LoadingProps } from './Loading.types';

export const Loading = ({ size = 50, strokeWidth = 2, color }: LoadingProps) => {
    const theme = useTheme();

    return <WaveLoading stroke={color || theme.palette.primary.main} count={2} {...{ size, strokeWidth }} />;
};
