import { ReactComponent as BusinessIcon } from '../../../../assets/svg/business.svg';
import { ReactComponent as PersonIcon } from '../../../../assets/svg/person.svg';
import { RolesEnum } from '../../../../types/firestore/users/users.types';
import { RegisterFormRoleButtonsData } from './RegisterForm.types';

export const REGISTER_FORM_ROLES_BUTTONS_DATA = Object.freeze<RegisterFormRoleButtonsData>({
    [RolesEnum.RegularUser]: {
        icon: PersonIcon,
    },
    [RolesEnum.AssociationUser]: {
        icon: BusinessIcon,
    },
});
