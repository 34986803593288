import { Action } from 'redux';

import { Association, Associations } from '../../../types/firestore/associations/associations.types';
import { SignOutAction } from '../../miscellaneous/miscellaneousActions/miscellaneousActions.types';

export const LISTEN_TO_ASSOCIATION_STARTED = 'LISTEN_TO_ASSOCIATION_STARTED';
export const LISTEN_TO_ASSOCIATION_RECEIVED_DATA = 'LISTEN_TO_ASSOCIATION_RECEIVED_DATA';
export const LISTEN_TO_ASSOCIATION_RECEIVED_ERROR = 'LISTEN_TO_ASSOCIATION_RECEIVED_ERROR';
export const LISTEN_TO_SUBASSOCIATIONS_STARTED = 'LISTEN_TO_SUBASSOCIATIONS_STARTED';
export const LISTEN_TO_SUBASSOCIATIONS_RECEIVED_DATA = 'LISTEN_TO_SUBASSOCIATIONS_RECEIVED_DATA';
export const LISTEN_TO_SUBASSOCIATIONS_RECEIVED_ERROR = 'LISTEN_TO_SUBASSOCIATIONS_RECEIVED_ERROR';

export interface ListenToAssociationStartedAction extends Action {
    type: typeof LISTEN_TO_ASSOCIATION_STARTED;
    associationId: string;
}

export interface ListenToAssociationReceivedDataAction extends Action {
    type: typeof LISTEN_TO_ASSOCIATION_RECEIVED_DATA;
    associationId: string;
    data: Association;
}

export interface ListenToAssociationReceivedErrorAction extends Action {
    type: typeof LISTEN_TO_ASSOCIATION_RECEIVED_ERROR;
    associationId: string;
    error: Error;
}

export type AssociationsReducerActionTypes =
    | ListenToAssociationStartedAction
    | ListenToAssociationReceivedDataAction
    | ListenToAssociationReceivedErrorAction
    | SignOutAction;

export interface ListenToSubAssociationsStartedAction extends Action {
    type: typeof LISTEN_TO_SUBASSOCIATIONS_STARTED;
    createdBy: string;
}

export interface ListenToSubAssociationsReceivedDataAction extends Action {
    type: typeof LISTEN_TO_SUBASSOCIATIONS_RECEIVED_DATA;
    createdBy: string;
    data: Associations;
}

export interface ListenToSubAssociationsReceivedErrorAction extends Action {
    type: typeof LISTEN_TO_SUBASSOCIATIONS_RECEIVED_ERROR;
    createdBy: string;
    error: Error;
}

export type SubAssociationsReducerActionTypes =
    | ListenToSubAssociationsStartedAction
    | ListenToSubAssociationsReceivedDataAction
    | ListenToSubAssociationsReceivedErrorAction
    | SignOutAction;
