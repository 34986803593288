import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { useCallbackOpen } from '../../../../../../hooks/useCallbackOpen';
import { Button } from '../../../../../designSystem/Button/Button';
import { ButtonVariants } from '../../../../../designSystem/Button/Button.types';
import { AuthenticateDialog } from '../../../../AuthenticateDialog/AuthenticateDialog';

export const SignInButton = () => {
    const [openDialog, setDialogOpened, setDialogClosed] = useCallbackOpen();
    const { t, ready } = useTranslation('header');

    if (!ready) {
        return null;
    }

    return (
        <>
            <AuthenticateDialog open={openDialog} onClose={setDialogClosed} />
            <Button variant={ButtonVariants.Contained} onClick={setDialogOpened}>
                {t('actions.notSignedIn.signInButton.label')}
            </Button>
        </>
    );
};
